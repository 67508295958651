import React from "react";
import styled from "styled-components";
import { Menu, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  totalByTypeA,
  totalByTypeB,
  totalByTypeC,
  totalByTypeD,
  totalByTypes,
  clearAllDataTable,
  activeTypeByA,
  activeTypeByB,
  activeTypeByC,
  activeTypeByD,
  activeByTypes,
} from "../../redux/problems/problemsDucks";

const svg = (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z"
      fill="#707070"
    />
  </svg>
);
const Options = [
  {
    id: 1,
    title: "Infraestructura",
  },
  {
    id: 2,
    title: "Equipos",
  },
  {
    id: 3,
    title: "Personas",
  },
  {
    id: 4,
    title: "Safety",
  },
  {
    id: 5,
    title: "Security",
  },
  {
    id: 6,
    title: "Calidad",
  },
  {
    id: 7,
    title: "Operaciones",
  },
  {
    id: 8,
    title: "Tecnologia",
  },
  {
    id: 9,
    title: "Datos",
  },
  {
    id: 10,
    title: "Legal",
  },
  {
    id: 11,
    title: "Todos",
  },
];
function FilterComponent() {
  const [selectName, setSelectName] = React.useState("Filtros");
  const [activeInitiative, setActiveInitiative] = React.useState(false);
  const [activeNotInitiative, setActiveNotInitiative] = React.useState(false);
  const allProblems = useSelector((store) => store.problem.data);

  const dispatch = useDispatch();

  //Filter si tiene iniciativa
  const handleFilterInitiative = () => {
    setActiveInitiative(true);
    setActiveNotInitiative(false);
    dispatch(activeTypeByA(null));
    dispatch(activeTypeByB(null));
    dispatch(activeTypeByC(null));
    dispatch(activeTypeByD(null));
    dispatch(activeByTypes(null));
    dispatch(
      totalByTypeA([
        {
          id: 1,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "a" &&
              el.operacion === "Trafico" &&
              el.iniciativa_id
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "a" &&
              el.operacion === "Rampa" &&
              el.iniciativa_id
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "a" &&
              el.operacion === "Importaciones" &&
              el.iniciativa_id
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "a" &&
              el.operacion === "Exportaciones" &&
              el.iniciativa_id
          ),
        },
        {
          id: 5,
          total: allProblems.filter(
            (el) => el.tipo_problema === "a" && el.iniciativa_id
          ),
        },
      ])
    );
    dispatch(
      totalByTypeB([
        {
          id: 1,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "b" &&
              el.operacion === "Trafico" &&
              el.iniciativa_id
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "b" &&
              el.operacion === "Rampa" &&
              el.iniciativa_id
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "b" &&
              el.operacion === "Importaciones" &&
              el.iniciativa_id
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "b" &&
              el.operacion === "Exportaciones" &&
              el.iniciativa_id
          ),
        },
        {
          id: 5,
          total: allProblems.filter(
            (el) => el.tipo_problema === "b" && el.iniciativa_id
          ),
        },
      ])
    );
    dispatch(
      totalByTypeC([
        {
          id: 1,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "c" &&
              el.operacion === "Trafico" &&
              el.iniciativa_id
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "c" &&
              el.operacion === "Rampa" &&
              el.iniciativa_id
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "c" &&
              el.operacion === "Importaciones" &&
              el.iniciativa_id
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "c" &&
              el.operacion === "Exportaciones" &&
              el.iniciativa_id
          ),
        },
        {
          id: 5,
          total: allProblems.filter(
            (el) => el.tipo_problema === "c" && el.iniciativa_id
          ),
        },
      ])
    );
    dispatch(
      totalByTypeD([
        {
          id: 1,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "d" &&
              el.operacion === "Trafico" &&
              el.iniciativa_id
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "d" &&
              el.operacion === "Rampa" &&
              el.iniciativa_id
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "d" &&
              el.operacion === "Importaciones" &&
              el.iniciativa_id
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "d" &&
              el.operacion === "Exportaciones" &&
              el.iniciativa_id
          ),
        },
        {
          id: 5,
          total: allProblems.filter(
            (el) => el.tipo_problema === "d" && el.iniciativa_id
          ),
        },
      ])
    );
    dispatch(
      totalByTypes([
        {
          id: 1,
          total: allProblems.filter(
            (el) => el.operacion === "Trafico" && el.iniciativa_id
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) => el.operacion === "Rampa" && el.iniciativa_id
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) => el.operacion === "Importaciones" && el.iniciativa_id
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) => el.operacion === "Exportaciones" && el.iniciativa_id
          ),
        },
        {
          id: 5,
          total: allProblems.filter((el) => el.iniciativa_id),
        },
      ])
    );
    dispatch(clearAllDataTable([]));
    setSelectName("Filtros");
  };

  //Filter si no tiene iniciativa
  const handleFilterNotInitiative = () => {
    setActiveInitiative(false);
    setActiveNotInitiative(true);
    dispatch(activeTypeByA(null));
    dispatch(activeTypeByB(null));
    dispatch(activeTypeByC(null));
    dispatch(activeTypeByD(null));
    dispatch(activeByTypes(null));
    dispatch(
      totalByTypeA([
        {
          id: 1,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "a" &&
              el.operacion === "Trafico" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "a" &&
              el.operacion === "Rampa" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "a" &&
              el.operacion === "Importaciones" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "a" &&
              el.operacion === "Exportaciones" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 5,
          total: allProblems.filter(
            (el) => el.tipo_problema === "a" && el.iniciativa_id === null
          ),
        },
      ])
    );
    dispatch(
      totalByTypeB([
        {
          id: 1,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "b" &&
              el.operacion === "Trafico" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "b" &&
              el.operacion === "Rampa" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "b" &&
              el.operacion === "Importaciones" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "b" &&
              el.operacion === "Exportaciones" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 5,
          total: allProblems.filter(
            (el) => el.tipo_problema === "b" && el.iniciativa_id === null
          ),
        },
      ])
    );
    dispatch(
      totalByTypeC([
        {
          id: 1,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "c" &&
              el.operacion === "Trafico" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "c" &&
              el.operacion === "Rampa" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "c" &&
              el.operacion === "Importaciones" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "c" &&
              el.operacion === "Exportaciones" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 5,
          total: allProblems.filter(
            (el) => el.tipo_problema === "c" && el.iniciativa_id === null
          ),
        },
      ])
    );
    dispatch(
      totalByTypeD([
        {
          id: 1,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "d" &&
              el.operacion === "Trafico" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "d" &&
              el.operacion === "Rampa" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "d" &&
              el.operacion === "Importaciones" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) =>
              el.tipo_problema === "d" &&
              el.operacion === "Exportaciones" &&
              el.iniciativa_id === null
          ),
        },
        {
          id: 5,
          total: allProblems.filter(
            (el) => el.tipo_problema === "d" && el.iniciativa_id === null
          ),
        },
      ])
    );
    dispatch(
      totalByTypes([
        {
          id: 1,
          total: allProblems.filter(
            (el) => el.operacion === "Trafico" && el.iniciativa_id === null
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) => el.operacion === "Rampa" && el.iniciativa_id === null
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) =>
              el.operacion === "Importaciones" && el.iniciativa_id === null
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) =>
              el.operacion === "Exportaciones" && el.iniciativa_id === null
          ),
        },
        {
          id: 5,
          total: allProblems.filter((el) => el.iniciativa_id === null),
        },
      ])
    );
    dispatch(clearAllDataTable([]));
    setSelectName("Filtros");
  };

  //Filtrando por area
  const handleFilter = (el) => {
    setActiveInitiative(false);
    setActiveNotInitiative(false);
    dispatch(activeTypeByA(null));
    dispatch(activeTypeByB(null));
    dispatch(activeTypeByC(null));
    dispatch(activeTypeByD(null));
    dispatch(activeByTypes(null));
    switch (el.id) {
      case 1:
        dispatch(
          totalByTypeA([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeB([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeC([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeD([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
          ])
        );
        dispatch(
          totalByTypes([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Infraestructura"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.area_apoyo === "Infraestructura"
              ),
            },
          ])
        );
        break;
      case 2:
        dispatch(
          totalByTypeA([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "a" && el.area_apoyo === "Equipos"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeB([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "b" && el.area_apoyo === "Equipos"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeC([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "c" && el.area_apoyo === "Equipos"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeD([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "d" && el.area_apoyo === "Equipos"
              ),
            },
          ])
        );
        dispatch(
          totalByTypes([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Trafico" && el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.operacion === "Rampa" && el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Equipos"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.area_apoyo === "Equipos"),
            },
          ])
        );
        break;
      case 3:
        dispatch(
          totalByTypeA([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "a" && el.area_apoyo === "Personas"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeB([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "b" && el.area_apoyo === "Personas"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeC([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "c" && el.area_apoyo === "Personas"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeD([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "d" && el.area_apoyo === "Personas"
              ),
            },
          ])
        );
        dispatch(
          totalByTypes([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Trafico" && el.area_apoyo === "Personas"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.operacion === "Rampa" && el.area_apoyo === "Personas"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Personas"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.area_apoyo === "Personas"),
            },
          ])
        );
        break;
      case 4:
        dispatch(
          totalByTypeA([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "a" && el.area_apoyo === "Safety"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeB([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "b" && el.area_apoyo === "Safety"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeC([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "c" && el.area_apoyo === "Safety"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeD([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Safety"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "d" && el.area_apoyo === "Safety"
              ),
            },
          ])
        );
        dispatch(
          totalByTypes([
            {
              id: 1,
              total: allProblems.filter(
                (el) => el.operacion === "Trafico" && el.area_apoyo === "Safety"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.operacion === "Rampa" && el.area_apoyo === "Safety"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Importaciones" && el.area_apoyo === "Safety"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Exportaciones" && el.area_apoyo === "Safety"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.area_apoyo === "Safety"),
            },
          ])
        );
        break;
      case 5:
        dispatch(
          totalByTypeA([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "a" && el.area_apoyo === "Security"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeB([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "b" && el.area_apoyo === "Security"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeC([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "c" && el.area_apoyo === "Security"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeD([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "d" && el.area_apoyo === "Security"
              ),
            },
          ])
        );
        dispatch(
          totalByTypes([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Trafico" && el.area_apoyo === "Security"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.operacion === "Rampa" && el.area_apoyo === "Security"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Security"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.area_apoyo === "Security"),
            },
          ])
        );
        break;
      case 6:
        dispatch(
          totalByTypeA([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "a" && el.area_apoyo === "Calidad"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeB([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "b" && el.area_apoyo === "Calidad"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeC([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "c" && el.area_apoyo === "Calidad"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeD([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "d" && el.area_apoyo === "Calidad"
              ),
            },
          ])
        );
        dispatch(
          totalByTypes([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Trafico" && el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.operacion === "Rampa" && el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Calidad"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.area_apoyo === "Calidad"),
            },
          ])
        );
        break;
      case 7:
        dispatch(
          totalByTypeA([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" && el.area_apoyo === "Operaciones"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeB([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" && el.area_apoyo === "Operaciones"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeC([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" && el.area_apoyo === "Operaciones"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeD([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" && el.area_apoyo === "Operaciones"
              ),
            },
          ])
        );
        dispatch(
          totalByTypes([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Trafico" && el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Rampa" && el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Operaciones"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.area_apoyo === "Operaciones"
              ),
            },
          ])
        );
        break;
      case 8:
        dispatch(
          totalByTypeA([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" && el.area_apoyo === "Tecnologia"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeB([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" && el.area_apoyo === "Tecnologia"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeC([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" && el.area_apoyo === "Tecnologia"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeD([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" && el.area_apoyo === "Tecnologia"
              ),
            },
          ])
        );
        dispatch(
          totalByTypes([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Trafico" && el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Rampa" && el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Tecnologia"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.area_apoyo === "Tecnologia"),
            },
          ])
        );
        break;
      case 9:
        dispatch(
          totalByTypeA([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "a" && el.area_apoyo === "Datos"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeB([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "b" && el.area_apoyo === "Datos"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeC([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "c" && el.area_apoyo === "Datos"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeD([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Datos"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "d" && el.area_apoyo === "Datos"
              ),
            },
          ])
        );
        dispatch(
          totalByTypes([
            {
              id: 1,
              total: allProblems.filter(
                (el) => el.operacion === "Trafico" && el.area_apoyo === "Datos"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.operacion === "Rampa" && el.area_apoyo === "Datos"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Importaciones" && el.area_apoyo === "Datos"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Exportaciones" && el.area_apoyo === "Datos"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.area_apoyo === "Datos"),
            },
          ])
        );
        break;
      case 10:
        dispatch(
          totalByTypeA([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "a" && el.area_apoyo === "Legal"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeB([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "b" && el.area_apoyo === "Legal"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeC([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "c" && el.area_apoyo === "Legal"
              ),
            },
          ])
        );
        dispatch(
          totalByTypeD([
            {
              id: 1,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Trafico" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Rampa" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Importaciones" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" &&
                  el.operacion === "Exportaciones" &&
                  el.area_apoyo === "Legal"
              ),
            },
            {
              id: 5,
              total: allProblems.filter(
                (el) => el.tipo_problema === "d" && el.area_apoyo === "Legal"
              ),
            },
          ])
        );
        dispatch(
          totalByTypes([
            {
              id: 1,
              total: allProblems.filter(
                (el) => el.operacion === "Trafico" && el.area_apoyo === "Legal"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.operacion === "Rampa" && el.area_apoyo === "Legal"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Importaciones" && el.area_apoyo === "Legal"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.operacion === "Exportaciones" && el.area_apoyo === "Legal"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.area_apoyo === "Legal"),
            },
          ])
        );
        break;
      case 11:
        dispatch(
          totalByTypeA([
            {
              id: 1,
              total: allProblems.filter(
                (el) => el.tipo_problema === "a" && el.operacion === "Trafico"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.tipo_problema === "a" && el.operacion === "Rampa"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" && el.operacion === "Importaciones"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "a" && el.operacion === "Exportaciones"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.tipo_problema === "a"),
            },
          ])
        );
        dispatch(
          totalByTypeB([
            {
              id: 1,
              total: allProblems.filter(
                (el) => el.tipo_problema === "b" && el.operacion === "Trafico"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.tipo_problema === "b" && el.operacion === "Rampa"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" && el.operacion === "Importaciones"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "b" && el.operacion === "Exportaciones"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.tipo_problema === "b"),
            },
          ])
        );
        dispatch(
          totalByTypeC([
            {
              id: 1,
              total: allProblems.filter(
                (el) => el.tipo_problema === "c" && el.operacion === "Trafico"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.tipo_problema === "c" && el.operacion === "Rampa"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" && el.operacion === "Importaciones"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "c" && el.operacion === "Exportaciones"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.tipo_problema === "c"),
            },
          ])
        );
        dispatch(
          totalByTypeD([
            {
              id: 1,
              total: allProblems.filter(
                (el) => el.tipo_problema === "d" && el.operacion === "Trafico"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.tipo_problema === "d" && el.operacion === "Rampa"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" && el.operacion === "Importaciones"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) =>
                  el.tipo_problema === "d" && el.operacion === "Exportaciones"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.tipo_problema === "d"),
            },
          ])
        );
        dispatch(
          totalByTypes([
            {
              id: 1,
              total: allProblems.filter(
                (el) => el.tipo_problema && el.operacion === "Trafico"
              ),
            },
            {
              id: 2,
              total: allProblems.filter(
                (el) => el.tipo_problema && el.operacion === "Rampa"
              ),
            },
            {
              id: 3,
              total: allProblems.filter(
                (el) => el.tipo_problema && el.operacion === "Importaciones"
              ),
            },
            {
              id: 4,
              total: allProblems.filter(
                (el) => el.tipo_problema && el.operacion === "Exportaciones"
              ),
            },
            {
              id: 5,
              total: allProblems.filter((el) => el.tipo_problema),
            },
          ])
        );

        break;
      default:
        break;
    }

    setSelectName(el.title);
    dispatch(clearAllDataTable([]));
  };

  const menu = (
    <Menu>
      {Options.map((el, i) => {
        return (
          <Menu.Item key={i}>
            <ItemFilter onClick={() => handleFilter(el)}>{el.title}</ItemFilter>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <FilterContent>
      <ItemFilter
        onClick={handleFilterInitiative}
        style={{
          textDecorationLine: activeInitiative ? "underline" : "none",
        }}
      >
        Con iniciativa
      </ItemFilter>
      <ItemFilter
        onClick={handleFilterNotInitiative}
        style={{
          textDecorationLine: activeNotInitiative ? "underline" : "none",
        }}
      >
        Sin iniciativa
      </ItemFilter>
      <ItemFilter>
        <Dropdown overlay={menu} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <span>{selectName}</span>

            <span style={{ left: "0.2em" }}>{svg}</span>
          </a>
        </Dropdown>
      </ItemFilter>
    </FilterContent>
  );
}
export default FilterComponent;
const FilterContent = styled.div`
  display: flex;
  align-items: center;
  span {
    font-family: var(--font-opensans);
    font-style: normal;
    font-weight: normal;
    font-size: var(--body);
    color: var(--blue-dark);
    position: relative;
  }
`;
const ItemFilter = styled.div`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: normal;
  font-size: var(--body);
  line-height: 20px;
  color: var(--blue-dark);
  margin: 0 0.5em;
  cursor: pointer;
`;
