const dataInitial = {
  data: [],
  dataByOpeAndTypes: [],
  filterCountry: "",
  activeFilter: false,
  filterDate: "",
  filterSeason: "",
  filterCriticality: "",
  totalTypeA: [],
  totalTypeB: [],
  totalTypeC: [],
  totalTypeD: [],
  totalTypes: [],
  activeTypeA: null,
  activeTypeB: null,
  activeTypeC: null,
  activeTypeD: null,
  activeTypes: null,
};

const GET_ALL_DATA = "GET_ALL_DATA";
const FILTER_DATA_BY_OPERATION_TYPES = "FILTER_DATA_BY_OPERATION_TYPES";
const CLEAR_ALL_DATA_TABLE = "CLEAR_ALL_DATA_TABLE";
const FILTER_BY_COUNTRY = "FILTER_BY_COUNTRY";
const FILTER_BY_DATE = "FILTER_BY_DATE";
const FILTER_BY_SEASON = "FILTER_BY_SEASON";
const FILTER_BY_CRITICALITY = "FILTER_BY_CRITICALITY";
const TOTAL_BY_TYPE_A = "TOTAL_BY_TYPE_A";
const TOTAL_BY_TYPE_B = "TOTAL_BY_TYPE_B";
const TOTAL_BY_TYPE_C = "TOTAL_BY_TYPE_C";
const TOTAL_BY_TYPE_D = "TOTAL_BY_TYPE_D";
const TOTAL_BY_TYPES = "TOTAL_BY_TYPES";
const ACTIVE_TYPE_A = "ACTIVE_TYPE_A";
const ACTIVE_TYPE_B = "ACTIVE_TYPE_B";
const ACTIVE_TYPE_C = "ACTIVE_TYPE_C";
const ACTIVE_TYPE_D = "ACTIVE_TYPE_D";
const ACTIVE_TYPES = "ACTIVE_TYPES";

export default function problemReducer(state = dataInitial, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_DATA:
      return { ...state, data: payload };

    case FILTER_DATA_BY_OPERATION_TYPES:
      return { ...state, dataByOpeAndTypes: payload };

    case CLEAR_ALL_DATA_TABLE:
      return { ...state, dataByOpeAndTypes: [] };

    case FILTER_BY_COUNTRY: {
      const filterCountry = state.data.filter((el) =>
        el.pais === null ? "" : el.pais?.includes(payload)
      );
      return {
        ...state,
        data: payload.dataByCountry === "" ? state.data : filterCountry,
        activeFilter: payload.activeFilter,
      };
    }

    case FILTER_BY_SEASON: {
      const filterSeason = state.data.filter((el) =>
        el.estacion === null ? "" : el.estacion?.includes(payload)
      );
      return {
        ...state,
        data: payload.dataBySeason === "" ? state.data : filterSeason,
      };
    }

    case FILTER_BY_CRITICALITY: {
      const filterCriticality = state.data.filter((el) =>
        el.criticidad === null ? "" : el.criticidad?.includes(payload)
      );
      return {
        ...state,
        data: payload.dataBySesaon === "" ? state.data : filterCriticality,
      };
    }

    case FILTER_BY_DATE: {
      const filterDate = state.data.filter((el) =>
        el.fecha_creacion?.toLowerCase().includes(payload.toLowerCase())
      );

      return { ...state, data: filterDate };
    }
    case TOTAL_BY_TYPE_A: {
      return { ...state, totalTypeA: payload };
    }
    case TOTAL_BY_TYPE_B: {
      return { ...state, totalTypeB: payload };
    }
    case TOTAL_BY_TYPE_C: {
      return { ...state, totalTypeC: payload };
    }
    case TOTAL_BY_TYPE_D: {
      return { ...state, totalTypeD: payload };
    }
    case TOTAL_BY_TYPES: {
      return { ...state, totalTypes: payload };
    }
    case ACTIVE_TYPE_A: {
      return { ...state, activeTypeA: payload };
    }
    case ACTIVE_TYPE_B: {
      return { ...state, activeTypeB: payload };
    }
    case ACTIVE_TYPE_C: {
      return { ...state, activeTypeC: payload };
    }
    case ACTIVE_TYPE_D: {
      return { ...state, activeTypeD: payload };
    }
    case ACTIVE_TYPES: {
      return { ...state, activeTypes: payload };
    }

    default:
      return { ...state };
  }
}

export const getAllData = (data) => (dispatch) => {
  dispatch({ type: GET_ALL_DATA, payload: data });
};
export const filterByOperationsAndTypes = (dataByOperation) => (dispatch) => {
  dispatch({ type: FILTER_DATA_BY_OPERATION_TYPES, payload: dataByOperation });
};
export const clearAllDataTable = (dataByOpeAndArea) => (dispatch) => {
  dispatch({ type: CLEAR_ALL_DATA_TABLE, payload: dataByOpeAndArea });
};
export const filterByCountry = (dataByCountry, activeFilter) => (dispatch) => {
  dispatch({
    type: FILTER_BY_COUNTRY,
    payload: { dataByCountry, activeFilter },
  });
};
export const filterByDate = (dataByDate) => (dispatch) => {
  dispatch({ type: FILTER_BY_DATE, payload: dataByDate });
};
export const filterBySeason = (dataBySeason) => (dispatch) => {
  dispatch({ type: FILTER_BY_SEASON, payload: dataBySeason });
};
export const filterByCriticality = (dataByCriticality) => (dispatch) => {
  dispatch({ type: FILTER_BY_CRITICALITY, payload: dataByCriticality });
};
export const totalByTypeA = (data) => (dispatch) => {
  dispatch({ type: TOTAL_BY_TYPE_A, payload: data });
};

export const totalByTypeB = (data) => (dispatch) => {
  dispatch({ type: TOTAL_BY_TYPE_B, payload: data });
};
export const totalByTypeC = (data) => (dispatch) => {
  dispatch({ type: TOTAL_BY_TYPE_C, payload: data });
};
export const totalByTypeD = (data) => (dispatch) => {
  dispatch({ type: TOTAL_BY_TYPE_D, payload: data });
};
export const totalByTypes = (data) => (dispatch) => {
  dispatch({ type: TOTAL_BY_TYPES, payload: data });
};

export const activeTypeByA = (data) => (dispatch) => {
  dispatch({ type: ACTIVE_TYPE_A, payload: data });
};

export const activeTypeByB = (data) => (dispatch) => {
  dispatch({ type: ACTIVE_TYPE_B, payload: data });
};

export const activeTypeByC = (data) => (dispatch) => {
  dispatch({ type: ACTIVE_TYPE_C, payload: data });
};

export const activeTypeByD = (data) => (dispatch) => {
  dispatch({ type: ACTIVE_TYPE_D, payload: data });
};

export const activeByTypes = (data) => (dispatch) => {
  dispatch({ type: ACTIVE_TYPES, payload: data });
};
