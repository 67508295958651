import React from "react";
export const data = [
  {
    title: "Portafolio",
    icon: (
      <svg
        width="140"
        height="119"
        viewBox="0 0 140 119"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="66.9145"
          cy="88.9145"
          r="29.9145"
          fill="#0060D9"
          fillOpacity="0.8"
        />
        <circle
          cx="110.085"
          cy="31.1111"
          r="29.9145"
          fill="#0060D9"
          fill0pacity="0.8"
        />
        <circle
          cx="11.9658"
          cy="92.1368"
          r="11.9658"
          fill="#0060D9"
          fill0pacity="0.8"
        />
        <circle
          cx="49.06"
          cy="17.9487"
          r="17.9487"
          fill="#0060D9"
          fill0pacity="0.8"
        />
      </svg>
    ),
    route: "/portfolio",
  },
  {
    title: "Roadmap",
    icon: (
      <svg
        width="174"
        height="118"
        viewBox="0 0 174 118"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="52.116"
          y="118"
          width="35"
          height="121.884"
          transform="rotate(-90 52.116 118)"
          fill="#317DDE"
        />
        <rect
          x="26.0579"
          y="76"
          width="35"
          height="121.884"
          transform="rotate(-90 26.0579 76)"
          fill="#317DDE"
        />
        <rect
          y="35"
          width="35"
          height="121.884"
          transform="rotate(-90 0 35)"
          fill="#317DDE"
        />
      </svg>
    ),
    route: "/roadmap",
  },
  {
    title: "Estaciones",
    icon: (
      <svg
        width="120"
        height="118"
        viewBox="0 0 120 118"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="82.1476" width="35.4362" height="35.4362" fill="#317DDE" />
        <rect
          x="41.8792"
          y="82.1476"
          width="36.2416"
          height="35.4362"
          fill="#317DDE"
        />
        <rect
          x="84.5637"
          y="82.1476"
          width="35.4362"
          height="35.4362"
          fill="#317DDE"
        />
        <rect y="41.0738" width="35.4362" height="35.4362" fill="#317DDE" />
        <rect
          x="41.8792"
          y="41.0738"
          width="36.2416"
          height="35.4362"
          fill="#317DDE"
        />
        <rect
          x="84.5637"
          y="41.0738"
          width="35.4362"
          height="35.4362"
          fill="#317DDE"
        />
        <rect width="35.4362" height="35.4362" fill="#317DDE" />
        <rect x="41.8792" width="36.2416" height="35.4362" fill="#317DDE" />
        <rect x="84.5637" width="35.4362" height="35.4362" fill="#317DDE" />
      </svg>
    ),
    route: "/seasons",
  },
  {
    title: "Iniciativas",
    icon: (
      <svg
        width="130"
        height="116"
        viewBox="0 0 130 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H37.7273L48.1818 15.9091H130V115.455H0V0Z"
          fill="#317DDE"
        />
      </svg>
    ),
    route: "/initiatives",
  },
  {
    title: "Embudo",
    icon: (
      <svg
        width="160"
        height="118"
        viewBox="0 0 160 118"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80 117.264L56.8079 117.264V83.269L0 0H160L103.713 82.5047V117.264L80 117.264Z"
          fill="#317DDE"
        />
      </svg>
    ),
    route: "/funnel",
  },
  {
    title: "Clientes",
    icon: (
      <svg
        width="145"
        height="120"
        viewBox="0 0 145 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121.778 0H24V102.222H0V120H144.889V102.222H121.778V0Z"
          fill="#317DDE"
        />
      </svg>
    ),
    route: "/customers",
  },
  {
    title: "Problemas",
    icon: (
      <svg
        width="156"
        height="120"
        viewBox="0 0 156 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="39.0698" width="42.9268" height="80.9302" fill="#317DDE" />
        <rect x="55.6099" width="43.9024" height="120" fill="#317DDE" />
        <rect
          x="112.195"
          y="31.6279"
          width="42.9268"
          height="88.3721"
          fill="#317DDE"
        />
      </svg>
    ),
    route: "/problems",
  },
];
