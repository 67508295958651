import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

function TeamContent(props) {
  const { dataShow } = props;

  return (
    <Wrapper>
      {/**Equipo */}
      <Container>
        <ContentStyled style={{ color: "var(--blue-dark)" }}>
          Cliente interno
        </ContentStyled>
        <ContentStyled>{dataShow[0]?.cliente_int}</ContentStyled>
      </Container>
      <Container>
        <ContentStyled style={{ color: "var(--blue-dark)" }}>
          Ingeniero a cargo
        </ContentStyled>
        <ContentStyled>{dataShow[0]?.ing_exop}</ContentStyled>
      </Container>
      <Container>
        <ContentStyled style={{ color: "var(--blue-dark)" }}>
          Ingeniero backup
        </ContentStyled>
        <ContentStyled>{dataShow[0]?.ing_exop_b}</ContentStyled>
      </Container>
    </Wrapper>
  );
}

TeamContent.propTypes = {
  dataShow: PropTypes.object,
};
export default TeamContent;

const Wrapper = styled.div`
  padding: 1.2em 1.4em;
`;
const ContentStyled = styled.p`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: normal;
  font-size: var(--body);
  line-height: 20px;
  color: var(--gray-dark);
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
