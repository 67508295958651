import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";

import config from "../../config";
import { getAllObservations } from "../../redux/observation/observationDucks";
import ListObservations from "../Observations/List";
import FilterTabs from "../Observations/Filter";
import TeamContent from "./TeamContent";
import DetailContent from "./DetailContent";

function DetailByInitiative(props) {
  const { idInitiative } = props;
  const allDataInitiatives = useSelector((store) => store.initiative.data);
  const dataListObservation = useSelector((store) => store.observation.data);

  const [tab, setTab] = React.useState(1);
  const dispatch = useDispatch();

  React.useEffect(() => {
    getDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataList = () => {
    axios
      .get(`${config.api_sheet}/observaciones`)
      .then((res) => {
        dispatch(getAllObservations(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const compareInitiativeWithId = allDataInitiatives.filter(
    (el) => el.id === idInitiative
  );

  const tabAction = (action) => {
    if (action === 1) {
      setTab(1);
    } else if (action === 2) {
      setTab(2);
    } else if (action === 3) {
      setTab(3);
    }
  };
  return (
    <>
      <TitleStyled>{compareInitiativeWithId[0]?.titulo_proy}</TitleStyled>
      <BodyStyled>{compareInitiativeWithId[0]?.descr}</BodyStyled>
      {compareInitiativeWithId.length > 0 && (
        <FilterTabs
          actionTab1={() => tabAction(1)}
          actionTab2={() => tabAction(2)}
          actionTab3={() => tabAction(3)}
          select={tab}
        />
      )}
      {tab === 1 ? (
        <ListObservations data={dataListObservation} idParams={idInitiative} />
      ) : tab === 2 ? (
        <TeamContent dataShow={compareInitiativeWithId} />
      ) : (
        <DetailContent dataShow={compareInitiativeWithId} />
      )}
    </>
  );
}
DetailByInitiative.propTypes = {
  idInitiative: PropTypes.string,
};
export default DetailByInitiative;

const TitleStyled = styled.h1`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: var(--subtitle);
  color: var(--blue-dark);
  padding: 15px 15px 5px;
`;
const BodyStyled = styled.p`
  margin: auto;
  color: var(--blue-dark);
  font-size: var(--body);
  overflow-y: auto;
  padding: 5px 15px;
  margin-bottom: 1em;
  height: 80px;
`;
