import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { filterByCategory } from "../../../redux/initiative/initiativeDucks";

export const types = [
  { id: 1, name: "Modelo operacional", color: " rgba(211, 215, 13, 0.5)" },
  {
    id: 2,
    name: "Mejora de infraestructura",
    color: "rgba(215, 110, 13, 0.5)",
  },
  { id: 3, name: "Proyectos transversales", color: "rgba(49, 125, 222, 0.5)" },
  {
    id: 4,
    name: "Integración con clientes",
    color: "rgba(125, 229, 254, 0.5)",
  },
  { id: 5, name: "Futuro del trabajo", color: "rgba(0, 0, 0, 0.5)" },
  { id: "todos", name: "Todos", color: "" },
];

const Filters = ({ getFilter }) => {
  const [active, setActive] = useState({ id: 0, name: "" });
  const [nameSelect, setNameSelect] = useState("Focos de mejora");
  const dispatch = useDispatch();

  const allDataInitiatives = useSelector((store) => store.initiative.data);

  const styleBase = (type) => ({
    height: 26,
    display: "flex",
    alignItems: "center",
    color: "var(--blue-dark)",
    lineHeight: "0.05em",
    fontWeight: type.id === active.id ? "400" : "400",
    cursor: "pointer",
    userSelect: "none",
    fontFamily: "var(--font-opensans)",
    letterSpacing: "0.05em",
  });

  const category_1 = allDataInitiatives.filter(
    (el) => parseInt(el.categoria) === 1
  );
  const category_2 = allDataInitiatives.filter(
    (el) => parseInt(el.categoria) === 2
  );
  const category_3 = allDataInitiatives.filter(
    (el) => parseInt(el.categoria) === 3
  );
  const category_4 = allDataInitiatives.filter(
    (el) => parseInt(el.categoria) === 4
  );
  const category_5 = allDataInitiatives.filter(
    (el) => parseInt(el.categoria) === 5
  );

  const updateActiv = (type) => {
    switch (type.id) {
      case 1:
        dispatch(filterByCategory(category_1));
        break;
      case 2:
        dispatch(filterByCategory(category_2));
        break;
      case 3:
        dispatch(filterByCategory(category_3));
        break;
      case 4:
        dispatch(filterByCategory(category_4));
        break;
      case 5:
        dispatch(filterByCategory(category_5));
        break;
      case "todos":
        dispatch(filterByCategory(allDataInitiatives));
        break;

      default:
        break;
    }

    if (active.id !== type.id) {
      setActive(type);
      getFilter(type);
      setNameSelect(type.name);
    } else {
      setActive({ id: 0, name: "" });
      getFilter({ id: 0, name: "" });
    }
  };

  const menu = (
    <Menu>
      {types.map((type) => (
        <Menu.Item
          key={type.id}
          style={{ ...styleBase(type) }}
          onClick={() => updateActiv(type)}
        >
          <Circle style={{ background: type.color }}></Circle>
          {type.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <TitleLinkStyled
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          {nameSelect} <DownOutlined />
        </TitleLinkStyled>
      </Dropdown>
    </>
  );
};

Filters.propTypes = {
  getFilter: PropTypes.any,
};
export default Filters;

const TitleLinkStyled = styled.a`
  :hover {
    color: var(--blue-dark);
  }
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: normal;
  font-size: var(--body);
  color: var(--blue-dark);
`;
const Circle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 0.5em;
`;
