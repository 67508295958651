import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

function Problem(props) {
  const { dataShow } = props;

  const labelCriticidad =
    dataShow.criticidad === "1"
      ? "Bajo"
      : dataShow.criticidad === "2"
      ? "Moderado"
      : dataShow.criticidad === "3"
      ? "Alto"
      : dataShow.criticidad === "4"
      ? "Muy alto"
      : "";

  const labelTypeProblem =
    dataShow.tipo_problema === "a"
      ? "Falta de estándar"
      : dataShow.tipo_problema === "b"
      ? "Desviación al estándar"
      : dataShow.tipo_problema === "c"
      ? "Mejora al estándar"
      : dataShow.tipo_problema === "d"
      ? "Cambio de estándar"
      : "";

  return (
    <Wrapper>
      <TitleStyled>Descripción</TitleStyled>
      <ContentStyled style={{ height: "12vh", overflowY: "auto" }}>
        {dataShow.descripcion}
      </ContentStyled>
      <Container>
        <ContentStyled style={{ color: "var(--blue-dark)" }}>
          Proceso
        </ContentStyled>
        <ContentStyled>{dataShow.proceso}</ContentStyled>
      </Container>
      <Container>
        <ContentStyled style={{ color: "var(--blue-dark)" }}>
          Sub Proceso
        </ContentStyled>
        <ContentStyled>{dataShow.subproceso}</ContentStyled>
      </Container>
      <Container>
        <ContentStyled style={{ color: "var(--blue-dark)" }}>
          Operación
        </ContentStyled>
        <ContentStyled>{dataShow.operacion}</ContentStyled>
      </Container>
      <Container>
        <ContentStyled style={{ color: "var(--blue-dark)" }}>
          Área de apoyo
        </ContentStyled>
        <ContentStyled>{dataShow.area_apoyo}</ContentStyled>
      </Container>
      <Container>
        <ContentStyled style={{ color: "var(--blue-dark)" }}>
          Criticidad
        </ContentStyled>
        <ContentStyled>{labelCriticidad}</ContentStyled>
      </Container>
      <Container>
        <ContentStyled style={{ color: "var(--blue-dark)" }}>
          Tipo de problema
        </ContentStyled>
        <ContentStyled>{labelTypeProblem}</ContentStyled>
      </Container>
      <Container>
        <ContentStyled style={{ color: "var(--blue-dark)" }}>
          Iniciativa
        </ContentStyled>
        <ContentStyled>{dataShow.iniciativa_id}</ContentStyled>
      </Container>
      <Container>
        <ContentStyled style={{ color: "var(--blue-dark)" }}>
          Dueño
        </ContentStyled>
        <ContentStyled>{dataShow.levantado_por}</ContentStyled>
      </Container>
    </Wrapper>
  );
}

Problem.propTypes = {
  dataShow: PropTypes.object,
};
export default Problem;

const Wrapper = styled.div``;
const TitleStyled = styled.h2`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: var(--subtitle);
  line-height: 27px;
  color: var(--blue-dark);
`;

const ContentStyled = styled.p`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: normal;
  font-size: var(--body);
  line-height: 20px;
  color: var(--gray-dark);
`;

const Container = styled.div`
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
`;
