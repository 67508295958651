import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import axios from "axios";
import config from "../../config";
import Item from "../Default/Item";
import { useDispatch } from "react-redux";
import { getAllObservations } from "../../redux/observation/observationDucks";

import { DeleteOutlined } from "@ant-design/icons";

function ListObservations(props) {
  const { data, idParams } = props;
  const [visible, setVisible] = useState("");

  const dispatch = useDispatch();

  const showIcon = (i) => {
    setVisible(() => {
      return { [i]: true };
    });
  };
  const hideIcon = (i) => {
    setVisible(() => {
      return { [i]: false };
    });
  };
  React.useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    const resp = await axios.get(`${config.api_sheet}/observaciones`);
    dispatch(getAllObservations(resp.data));
  };

  const deleteItem = async (id) => {
    await axios.delete(`${config.api_sheet}/observaciones/id/*${id}*`);
    getAllData();
  };

  return (
    <Wrapper>
      <Container>
        {data.map((el, i) => {
          return el.id_iniciativa === idParams ? (
            <div
              style={{ marginBottom: "1em", position: "relative" }}
              key={i}
              onMouseEnter={() => showIcon(i)}
              onMouseLeave={() => hideIcon(i)}
            >
              <Item
                backgroundColor={"var(--gray-medium)"}
                borderRadius={5}
                height={"auto"}
              >
                <BodyStyled>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <DateStyled>
                      {el.date} {el.type}
                    </DateStyled>
                    {visible[i] && (
                      <Icon onClick={() => deleteItem(el.id)}>
                        <DeleteOutlined />
                      </Icon>
                    )}
                  </div>
                  <DescriptionStyled>{el.content}</DescriptionStyled>
                </BodyStyled>
              </Item>
            </div>
          ) : null;
        })}
      </Container>
    </Wrapper>
  );
}
ListObservations.propTypes = {
  data: PropTypes.array.isRequired,
  idParams: PropTypes.any,
};

export default ListObservations;

const Wrapper = styled.div`
  height: 100%;
  p {
    margin-bottom: 0px;
    font-family: var(--font-opensans);
    font-style: normal;
  }
`;
const DateStyled = styled.p`
  font-weight: 600;
  font-size: var(--body);
  color: var(--gray-dark);
`;

const DescriptionStyled = styled.p`
  font-weight: 400;
  font-size: var(--body);
  color: var(--blue-dark);
`;

const Container = styled.div`
  padding: 0.5em 1em;
  margin-bottom: 1em;
  height: 380px;
  overflow: auto;
`;
const BodyStyled = styled.div`
  margin: 1em 0;
  padding: 10px;
`;

const Icon = styled.div`
  color: var(--blue-dark);
  cursor: pointer;
`;
