import React from "react";
import styled from "styled-components";
// import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  filterBySeasonAndStatus,
  clearFilterAllSeasons,
} from "../../redux/initiative/initiativeDucks";

function GridTableComponent() {
  // const { data } = props;
  const allDataInitiatives = useSelector((store) => store.initiative.data);
  const [active, setActive] = React.useState(false);
  const [showBeneficio, setShowBeneficio] = React.useState(false);

  const dispacth = useDispatch();
  const titleHeaderGrid = [
    {
      title: "USD",
    },
    {
      title: "L0",
    },
    {
      title: "L1",
    },
    {
      title: "L2",
    },
    {
      title: "L3",
    },
    {
      title: "L4",
    },
    {
      title: "L5",
    },
    {
      title: "ALL",
    },
  ];

  let statusL0 = allDataInitiatives.filter((el) => el.estado_wave === "L0");
  let statusL1 = allDataInitiatives.filter((el) => el.estado_wave === "L1");
  let statusL2 = allDataInitiatives.filter((el) => el.estado_wave === "L2");
  let statusL3 = allDataInitiatives.filter((el) => el.estado_wave === "L3");
  let statusL4 = allDataInitiatives.filter((el) => el.estado_wave === "L4");
  let statusL5 = allDataInitiatives.filter((el) => el.estado_wave === "L5");

  //by seasons by status L0
  const counterL0AndSCL = statusL0.filter((el) => el.estacion === "Santiago");
  const counterL0AndBOG = statusL0.filter((el) => el.estacion === "Bogotá");
  const counterL0AndMED = statusL0.filter((el) => el.estacion === "Medellin");
  const counterL0AndUIO = statusL0.filter((el) => el.estacion === "Quito");
  const counterL0AndOtros = statusL0.filter(
    (el) =>
      el.estacion !== "Quito" &&
      el.estacion !== "Bogotá" &&
      el.estacion !== "Santiago" &&
      el.estacion !== "Medellin"
  );

  //by seasons by status L1
  const counterL1AndSCL = statusL1.filter((el) => el.estacion === "Santiago");
  const counterL1AndBOG = statusL1.filter((el) => el.estacion === "Bogotá");
  const counterL1AndMED = statusL1.filter((el) => el.estacion === "Medellin");
  const counterL1AndUIO = statusL1.filter((el) => el.estacion === "Quito");
  const counterL1AndOtros = statusL1.filter(
    (el) =>
      el.estacion !== "Quito" &&
      el.estacion !== "Bogotá" &&
      el.estacion !== "Santiago" &&
      el.estacion !== "Medellin"
  );

  //by seasons by status L2
  const counterL2AndSCL = statusL2.filter((el) => el.estacion === "Santiago");
  const counterL2AndBOG = statusL2.filter((el) => el.estacion === "Bogotá");
  const counterL2AndMED = statusL2.filter((el) => el.estacion === "Medellin");
  const counterL2AndUIO = statusL2.filter((el) => el.estacion === "Quito");
  const counterL2AndOtros = statusL2.filter(
    (el) =>
      el.estacion !== "Quito" &&
      el.estacion !== "Bogotá" &&
      el.estacion !== "Santiago" &&
      el.estacion !== "Medellin"
  );

  //by seasons by status L3
  const counterL3AndSCL = statusL3.filter((el) => el.estacion === "Santiago");
  const counterL3AndBOG = statusL3.filter((el) => el.estacion === "Bogotá");
  const counterL3AndMED = statusL3.filter((el) => el.estacion === "Medellin");
  const counterL3AndUIO = statusL3.filter((el) => el.estacion === "Quito");
  const counterL3AndOtros = statusL3.filter(
    (el) =>
      el.estacion !== "Quito" &&
      el.estacion !== "Bogotá" &&
      el.estacion !== "Santiago" &&
      el.estacion !== "Medellin"
  );

  //by seasons by status L4
  const counterL4AndSCL = statusL4.filter((el) => el.estacion === "Santiago");
  const counterL4AndBOG = statusL4.filter((el) => el.estacion === "Bogotá");
  const counterL4AndMED = statusL4.filter((el) => el.estacion === "Medellin");
  const counterL4AndUIO = statusL4.filter((el) => el.estacion === "Quito");
  const counterL4AndOtros = statusL4.filter(
    (el) =>
      el.estacion !== "Quito" &&
      el.estacion !== "Bogotá" &&
      el.estacion !== "Santiago" &&
      el.estacion !== "Medellin"
  );

  //by seasons by status L5
  const counterL5AndSCL = statusL5.filter((el) => el.estacion === "Santiago");
  const counterL5AndBOG = statusL5.filter((el) => el.estacion === "Bogotá");
  const counterL5AndMED = statusL5.filter((el) => el.estacion === "Medellin");
  const counterL5AndUIO = statusL5.filter((el) => el.estacion === "Quito");
  const counterL5AndOtros = statusL5.filter(
    (el) =>
      el.estacion !== "Quito" &&
      el.estacion !== "Bogotá" &&
      el.estacion !== "Santiago" &&
      el.estacion !== "Medellin"
  );

  //Counter total by season
  const counterBySeasonSCL = allDataInitiatives.filter(
    (el) => el.estacion === "Santiago"
  );
  const counterBySeasonBOG = allDataInitiatives.filter(
    (el) => el.estacion === "Bogotá"
  );
  const counterBySeasonMDE = allDataInitiatives.filter(
    (el) => el.estacion === "Medellin"
  );
  const counterBySeasonUIO = allDataInitiatives.filter(
    (el) => el.estacion === "Quito"
  );
  const counterBySeasonOtros = allDataInitiatives.filter(
    (el) =>
      el.estacion !== "Santiago" &&
      el.estacion !== "Bogotá" &&
      el.estacion !== "Medellin" &&
      el.estacion !== "Quito"
  );
  const dataAll = [
    {
      total: "ALL",
    },
    {
      total: statusL0,
      action: "l0All",
      benef_captura: parseInt(
        statusL0.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: statusL1,
      action: "l1All",
      benef_captura: parseInt(
        statusL1.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: statusL2,
      action: "l2All",
      benef_captura: parseInt(
        statusL2.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: statusL3,
      action: "l3All",
      benef_captura: parseInt(
        statusL3.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: statusL4,
      action: "l4All",
      benef_captura: parseInt(
        statusL4.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: statusL5,
      action: "l5All",
      benef_captura: parseInt(
        statusL5.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: allDataInitiatives,
      action: "lStatusAll",
      benef_captura: parseInt(
        allDataInitiatives.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
  ];

  const handleActiveByL0 = (action) => {
    if (action === "l0AndSCL") {
      dispacth(filterBySeasonAndStatus(counterL0AndSCL));
      setActive(action);
    } else if (action === "l0AndBOG") {
      dispacth(filterBySeasonAndStatus(counterL0AndBOG));
      setActive(action);
    } else if (action === "l0AndMED") {
      dispacth(filterBySeasonAndStatus(counterL0AndMED));
      setActive(action);
    } else if (action === "l0AndUIO") {
      dispacth(filterBySeasonAndStatus(counterL0AndUIO));
      setActive(action);
    } else if (action === "l0AndOtros") {
      dispacth(filterBySeasonAndStatus(counterL0AndOtros));
      setActive(action);
    }
  };

  const totalSeasonByL0 = [
    {
      total: counterL0AndSCL,
      action: "l0AndSCL",
      benef_captura: parseInt(
        counterL0AndSCL.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL0AndBOG,
      action: "l0AndBOG",
      benef_captura: parseInt(
        counterL0AndBOG.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL0AndMED,
      action: "l0AndMED",
      benef_captura: parseInt(
        counterL0AndMED.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },

    {
      total: counterL0AndUIO,
      action: "l0AndUIO",
      benef_captura: parseInt(
        counterL0AndUIO.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL0AndOtros,
      action: "l0AndOtros",
      benef_captura: parseInt(
        counterL0AndOtros.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
  ];
  const handleActiveByL1 = (action) => {
    if (action === "l1AndSCL") {
      dispacth(filterBySeasonAndStatus(counterL1AndSCL));
      setActive(action);
    } else if (action === "l1AndBOG") {
      dispacth(filterBySeasonAndStatus(counterL1AndBOG));
      setActive(action);
    } else if (action === "l1AndMED") {
      dispacth(filterBySeasonAndStatus(counterL1AndMED));
      setActive(action);
    } else if (action === "l1AndUIO") {
      dispacth(filterBySeasonAndStatus(counterL1AndUIO));
      setActive(action);
    } else if (action === "l1AndOtros") {
      dispacth(filterBySeasonAndStatus(counterL1AndOtros));
      setActive(action);
    }
  };
  const totalSeasonByL1 = [
    {
      total: counterL1AndSCL,
      action: "l1AndSCL",
      benef_captura: parseInt(
        counterL1AndSCL.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL1AndBOG,
      action: "l1AndBOG",
      benef_captura: parseInt(
        counterL1AndBOG.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL1AndMED,
      action: "l1AndMED",
      benef_captura: parseInt(
        counterL1AndMED.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL1AndUIO,
      action: "l1AndUIO",
      benef_captura: parseInt(
        counterL1AndUIO.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL1AndOtros,
      action: "l1AndOtros",
      benef_captura: parseInt(
        counterL1AndOtros.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
  ];
  const handleActiveByL2 = (action) => {
    if (action === "l2AndSCL") {
      dispacth(filterBySeasonAndStatus(counterL2AndSCL));
      setActive(action);
    } else if (action === "l2AndBOG") {
      dispacth(filterBySeasonAndStatus(counterL2AndBOG));
      setActive(action);
    } else if (action === "l2AndMED") {
      dispacth(filterBySeasonAndStatus(counterL2AndMED));
      setActive(action);
    } else if (action === "l2AndUIO") {
      dispacth(filterBySeasonAndStatus(counterL2AndUIO));
      setActive(action);
    } else if (action === "l2AndOtros") {
      dispacth(filterBySeasonAndStatus(counterL2AndOtros));
      setActive(action);
    }
  };
  const totalSeasonByL2 = [
    {
      total: counterL2AndSCL,
      action: "l2AndSCL",
      benef_captura: parseInt(
        counterL2AndSCL.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL2AndBOG,
      action: "l2AndBOG",
      benef_captura: parseInt(
        counterL2AndBOG.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL2AndMED,
      action: "l2AndMED",
      benef_captura: parseInt(
        counterL2AndMED.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL2AndUIO,
      action: "l2AndUIO",
      benef_captura: parseInt(
        counterL2AndUIO.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL2AndOtros,
      action: "l2AndOtros",
      benef_captura: parseInt(
        counterL2AndOtros.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
  ];

  const handleActiveByL3 = (action) => {
    if (action === "l3AndSCL") {
      dispacth(filterBySeasonAndStatus(counterL3AndSCL));
      setActive(action);
    } else if (action === "l3AndBOG") {
      dispacth(filterBySeasonAndStatus(counterL3AndBOG));
      setActive(action);
    } else if (action === "l3AndMED") {
      dispacth(filterBySeasonAndStatus(counterL3AndMED));
      setActive(action);
    } else if (action === "l3AndUIO") {
      dispacth(filterBySeasonAndStatus(counterL3AndUIO));
      setActive(action);
    } else if (action === "l3AndOtros") {
      dispacth(filterBySeasonAndStatus(counterL3AndOtros));
      setActive(action);
    }
  };
  const totalSeasonByL3 = [
    {
      total: counterL3AndSCL,
      action: "l3AndSCL",
      benef_captura: parseInt(
        counterL3AndSCL.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL3AndBOG,
      action: "l3AndBOG",
      benef_captura: parseInt(
        counterL3AndBOG.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL3AndMED,
      action: "l3AndMED",
      benef_captura: parseInt(
        counterL3AndMED.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL3AndUIO,
      action: "l3AndUIO",
      benef_captura: parseInt(
        counterL3AndUIO.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL3AndOtros,
      action: "l3AndOtros",
      benef_captura: parseInt(
        counterL3AndOtros.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
  ];
  const handleActiveByL4 = (action) => {
    if (action === "l4AndSCL") {
      dispacth(filterBySeasonAndStatus(counterL4AndSCL));
      setActive(action);
    } else if (action === "l4AndBOG") {
      dispacth(filterBySeasonAndStatus(counterL4AndBOG));
      setActive(action);
    } else if (action === "l4AndMED") {
      dispacth(filterBySeasonAndStatus(counterL4AndMED));
      setActive(action);
    } else if (action === "l4AndUIO") {
      dispacth(filterBySeasonAndStatus(counterL4AndUIO));
      setActive(action);
    } else if (action === "l4AndOtros") {
      dispacth(filterBySeasonAndStatus(counterL4AndOtros));
      setActive(action);
    }
  };
  const totalSeasonByL4 = [
    {
      total: counterL4AndSCL,
      action: "l4AndSCL",
      benef_captura: parseInt(
        counterL4AndSCL.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL4AndBOG,
      action: "l4AndBOG",
      benef_captura: parseInt(
        counterL4AndBOG.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL4AndMED,
      action: "l4AndMED",
      benef_captura: parseInt(
        counterL4AndMED.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL4AndUIO,
      action: "l4AndUIO",
      benef_captura: parseInt(
        counterL4AndUIO.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL4AndOtros,
      action: "l4AndOtros",
      benef_captura: parseInt(
        counterL4AndOtros.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
  ];
  const handleActiveByL5 = (action) => {
    if (action === "l5AndSCL") {
      dispacth(filterBySeasonAndStatus(counterL5AndSCL));
      setActive(action);
    } else if (action === "l5AndBOG") {
      dispacth(filterBySeasonAndStatus(counterL5AndBOG));
      setActive(action);
    } else if (action === "l5AndMED") {
      dispacth(filterBySeasonAndStatus(counterL5AndMED));
      setActive(action);
    } else if (action === "l5AndUIO") {
      dispacth(filterBySeasonAndStatus(counterL5AndUIO));
      setActive(action);
    } else if (action === "l5AndOtros") {
      dispacth(filterBySeasonAndStatus(counterL5AndOtros));
      setActive(action);
    }
  };

  const totalSeasonByL5 = [
    {
      total: counterL5AndSCL,
      action: "l5AndSCL",
      benef_captura: parseInt(
        counterL5AndSCL.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL5AndBOG,
      action: "l5AndBOG",
      benef_captura: parseInt(
        counterL5AndBOG.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL5AndMED,
      action: "l5AndMED",
      benef_captura: parseInt(
        counterL5AndMED.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL5AndUIO,
      action: "l5AndUIO",
      benef_captura: parseInt(
        counterL5AndUIO.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterL5AndOtros,
      action: "l5AndOtros",
      benef_captura: parseInt(
        counterL5AndOtros.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
  ];
  //Filter show data by status totales
  const handleActiveByAllStatus = (action) => {
    if (action === "l0All") {
      dispacth(filterBySeasonAndStatus(statusL0));
      setActive(action);
    } else if (action === "l1All") {
      dispacth(filterBySeasonAndStatus(statusL1));
      setActive(action);
    } else if (action === "l2All") {
      dispacth(filterBySeasonAndStatus(statusL2));
      setActive(action);
    } else if (action === "l3All") {
      dispacth(filterBySeasonAndStatus(statusL3));
      setActive(action);
    } else if (action === "l4All") {
      dispacth(filterBySeasonAndStatus(statusL4));
      setActive(action);
    } else if (action === "l5All") {
      dispacth(filterBySeasonAndStatus(statusL5));
      setActive(action);
    } else if (action === "lStatusAll") {
      dispacth(filterBySeasonAndStatus(allDataInitiatives));
      setActive(action);
    }
  };

  //Filter show data by estaciones totales
  const handleActiveByAllSeasons = (action) => {
    if (action === "sclAll") {
      dispacth(filterBySeasonAndStatus(counterBySeasonSCL));
      setActive(action);
    } else if (action === "bogAll") {
      dispacth(filterBySeasonAndStatus(counterBySeasonBOG));
      setActive(action);
    } else if (action === "medAll") {
      dispacth(filterBySeasonAndStatus(counterBySeasonMDE));
      setActive(action);
    } else if (action === "uioAll") {
      dispacth(filterBySeasonAndStatus(counterBySeasonUIO));
      setActive(action);
    } else if (action === "otrosAll") {
      dispacth(filterBySeasonAndStatus(counterBySeasonOtros));
      setActive(action);
    }
  };

  const totalByAllSeasons = [
    {
      total: counterBySeasonSCL,
      action: "sclAll",
      benef_captura: parseInt(
        counterBySeasonSCL.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterBySeasonBOG,
      action: "bogAll",
      benef_captura: parseInt(
        counterBySeasonBOG.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterBySeasonMDE,
      action: "medAll",
      benef_captura: parseInt(
        counterBySeasonMDE.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterBySeasonUIO,
      action: "uioAll",
      benef_captura: parseInt(
        counterBySeasonUIO.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
    {
      total: counterBySeasonOtros,
      action: "otrosAll",
      benef_captura: parseInt(
        counterBySeasonOtros.reduce(
          (accu, currentValue) => accu - -currentValue.benef_captura,
          0
        )
      ),
    },
  ];
  const clear = () => {
    setActive("");
    dispacth(clearFilterAllSeasons());
  };
  const filterByUsd = (i) => {
    i === 0 && setShowBeneficio(!showBeneficio);
  };

  return (
    <>
      <HeaderStyled>
        <TitleStyled>Portafolio de iniciativas</TitleStyled>
        <Filter onClick={clear}>Todos</Filter>
      </HeaderStyled>
      <Wrapper>
        {titleHeaderGrid.map((el, i) => {
          return (
            <Grid key={i} onClick={() => filterByUsd(i)}>
              {el.title}
            </Grid>
          );
        })}
        <>
          <VerticalGrids className="col-start">
            <Grid style={{ marginBottom: "0.8em" }}>SCL</Grid>
            <Grid style={{ marginBottom: "0.8em" }}>BOG</Grid>
            <Grid style={{ marginBottom: "0.8em" }}>MDE</Grid>
            <Grid style={{ marginBottom: "0.8em" }}>UIO</Grid>
            <Grid>otros</Grid>
          </VerticalGrids>
          <VerticalGrids className="col-one col">
            {totalSeasonByL0.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "0.8em",
                    backgroundColor:
                      active === el.action
                        ? "var(--blue-medium)"
                        : "var(--gray-low)",
                    color: active === el.action ? "white" : "var(--blue-dark)",
                  }}
                  key={i}
                  onClick={() => handleActiveByL0(el.action)}
                >
                  {showBeneficio === true
                    ? el.benef_captura.toLocaleString()
                    : el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
          <VerticalGrids className="col-two col">
            {totalSeasonByL1.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "0.8em",
                    backgroundColor:
                      active === el.action
                        ? "var(--blue-medium)"
                        : "var(--gray-low)",
                    color: active === el.action ? "white" : "var(--blue-dark)",
                  }}
                  key={i}
                  onClick={() => handleActiveByL1(el.action)}
                >
                  {showBeneficio === true
                    ? el.benef_captura.toLocaleString()
                    : el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
          <VerticalGrids className="col-three col">
            {totalSeasonByL2.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "0.8em",
                    backgroundColor:
                      active === el.action
                        ? "var(--blue-medium)"
                        : "var(--gray-low)",
                    color: active === el.action ? "white" : "var(--blue-dark)",
                  }}
                  key={i}
                  onClick={() => handleActiveByL2(el.action)}
                >
                  {showBeneficio === true
                    ? el.benef_captura.toLocaleString()
                    : el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
          <VerticalGrids className="col-four col">
            {totalSeasonByL3.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "0.8em",
                    backgroundColor:
                      active === el.action
                        ? "var(--blue-medium)"
                        : "var(--gray-low)",
                    color: active === el.action ? "white" : "var(--blue-dark)",
                  }}
                  key={i}
                  onClick={() => handleActiveByL3(el.action)}
                >
                  {showBeneficio === true
                    ? el.benef_captura.toLocaleString()
                    : el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
          <VerticalGrids className="col-five col">
            {totalSeasonByL4.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "0.8em",
                    backgroundColor:
                      active === el.action
                        ? "var(--blue-medium)"
                        : "var(--gray-low)",
                    color: active === el.action ? "white" : "var(--blue-dark)",
                  }}
                  key={i}
                  onClick={() => handleActiveByL4(el.action)}
                >
                  {showBeneficio === true
                    ? el.benef_captura.toLocaleString()
                    : el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
          <VerticalGrids className="col-six col">
            {totalSeasonByL5.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "0.8em",
                    backgroundColor:
                      active === el.action
                        ? "var(--blue-medium)"
                        : "var(--gray-low)",
                    color: active === el.action ? "white" : "var(--blue-dark)",
                  }}
                  key={i}
                  onClick={() => handleActiveByL5(el.action)}
                >
                  {showBeneficio === true
                    ? el.benef_captura.toLocaleString()
                    : el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
          <VerticalGrids className="col-end">
            {totalByAllSeasons.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "0.8em",
                    backgroundColor:
                      active === el.action
                        ? "var(--blue-medium)"
                        : "var(--gray-medium)",
                    color: active === el.action ? "white" : "var(--blue-dark)",
                  }}
                  key={i}
                  onClick={() => handleActiveByAllSeasons(el.action)}
                >
                  {showBeneficio === true
                    ? el.benef_captura.toLocaleString()
                    : el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
        </>
        {dataAll.map((el, i) => {
          return (
            <Grid
              key={i}
              onClick={() => handleActiveByAllStatus(el.action)}
              style={{
                backgroundColor:
                  active === el.action
                    ? "var(--blue-medium)"
                    : "var(--gray-medium)",
                color: active === el.action ? "white" : "var(--blue-dark)",
                marginTop: "-1em",
              }}
            >
              {showBeneficio === true
                ? el.total === "ALL"
                  ? "ALL"
                  : el.benef_captura.toLocaleString()
                : el.total === "ALL"
                ? "ALL"
                : el.total.length}
            </Grid>
          );
        })}
      </Wrapper>
    </>
  );
}

// GridTableComponent.propTypes = {
//   // titleHeader: PropTypes.array,
//   data: PropTypes.array,
// };
export default GridTableComponent;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 1em;
  .col-start {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .col-one {
    grid-column-start: 2;
    grid-column-end: 2;
  }
  .col-two {
    grid-column-start: 3;
    grid-column-end: 3;
    div {
      background: var(--gray-low);
    }
  }
  .col-three {
    grid-column-start: 4;
    grid-column-end: 4;
    div {
      background: var(--gray-low);
    }
  }
  .col-four {
    grid-column-start: 5;
    grid-column-end: 5;
    div {
      background: var(--gray-low);
    }
  }
  .col-five {
    grid-column-start: 6;
    grid-column-end: 6;
    div {
      background: var(--gray-low);
    }
  }
  .col-six {
    grid-column-start: 7;
    grid-column-end: 7;
    div {
      background: var(--gray-low);
    }
  }
  .col-end {
    grid-column-start: 8;
    grid-column-end: 8;
  }
  .col {
    font-family: var(--font-opensans);
    font-style: normal;
    font-weight: normal;
    font-size: var(--total);
  }
`;

const Grid = styled.div`
  background: var(--gray-medium);
  height: 70px;
  // width: 70px;
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: var(--subtitle);
  line-height: 65px;
  text-align: center;
  color: var(--blue-dark);
  cursor: pointer;
`;
const VerticalGrids = styled.div``;

const TitleStyled = styled.h2`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: 1.3em;
  line-height: 27px;
  color: var(--blue-dark);
  @media (max-width: 991px) {
    margin-top: 1em;
  }
`;

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Filter = styled.div`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: normal;
  font-size: var(--total);
  line-height: 20px;
  text-decoration-line: underline;
  color: var(--blue-dark);
  cursor: pointer;
`;
