import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import DrawerComponent from "../Drawer/Drawer";
import ShowProblem from "../Problems/ShowProblem";

function TableComponent(props) {
  const { dataTableHeader, dataTable, titleTable } = props;
  const [visible, setVisible] = React.useState(false);
  const [hover, setHover] = React.useState("");
  const [dataShow, setDataShow] = React.useState({});

  const showDrawer = (el) => {
    setDataShow(el);
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const handleHover = (i, action) => {
    if (action === 1) {
      setHover(i);
    } else {
      setHover();
    }
  };
  const drawer = (
    <DrawerComponent
      title={dataShow.titulo}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={400}
    >
      <ShowProblem dataShow={dataShow} />
    </DrawerComponent>
  );
  return (
    <>
      {drawer}
      <HeaderContainer>
        <TitleStyled>{titleTable}</TitleStyled>
      </HeaderContainer>
      <Wrapper>
        <TableStyled>
          <thead>
            <tr>
              {dataTableHeader.map((el, i) => {
                return (
                  <th
                    key={i}
                    style={{ width: el.width ? `${el.width}%` : "auto" }}
                  >
                    {el.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {dataTable.map((el, i) => {
              return (
                <tr
                  key={i}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => showDrawer(el)}
                  onMouseEnter={() => handleHover(i, 1)}
                  onMouseLeave={() => handleHover(i, 2)}
                >
                  <td
                    style={{
                      backgroundColor: hover === i ? "var(--blue-medium)" : "",
                      color: hover === i ? "#fff" : "",
                      textAlign: "right",
                    }}
                  >
                    <div>
                      <p>{el.titulo}</p>
                      <p>{el.descripcion}</p>
                    </div>
                  </td>
                  <td
                    style={{
                      backgroundColor: hover === i ? "var(--blue-medium)" : "",
                      color: hover === i ? "#fff" : "",
                      textAlign: "right",
                    }}
                  >
                    <div>
                      <p> {el.proceso}</p>
                      <p> {el.subproceso}</p>
                    </div>
                  </td>
                  <td
                    style={{
                      backgroundColor: hover === i ? "var(--blue-medium)" : "",
                      color: hover === i ? "#fff" : "",
                    }}
                  >
                    {el.iniciativa_id}
                  </td>
                  <td
                    style={{
                      backgroundColor: hover === i ? "var(--blue-medium)" : "",
                      color: hover === i ? "#fff" : "",
                      textAlign: "right",
                    }}
                  >
                    {el.levantado_por}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </TableStyled>
      </Wrapper>
    </>
  );
}

TableComponent.propTypes = {
  dataTableHeader: PropTypes.array,
  dataTable: PropTypes.array,
  titleTable: PropTypes.string,
};
export default TableComponent;

const Wrapper = styled.div`
  :hover {
    overflow-y: auto;
  }
  overflow: hidden;
  display: block;
  height: 485px;
  @media (max-width: 647px) {
    width: 80vw;
    overflow: auto;
  }
`;
const TableStyled = styled.table`
  width: 47.5vw;
  border-collapse: separate;
  border-spacing: 0.5em 1em;
  table-layout: fixed;
  word-break: break-all;
  // height: 100%;
  margin: -15px 0px;
  @media (min-width: 1223px) {
    border-spacing: 1em;
  }
  @media (max-width: 1100px) {
    margin: 0 auto;
    width: 100%;
  }
  th {
    background: var(--gray-medium);
    height: 70px;
    font-family: var(--font-opensans);
    font-style: normal;
    font-weight: bold;
    font-size: var(--subtitle);
    line-height: 27px;
    text-align: center;
    color: var(--blue-dark);
    padding: 0px 15px;
  }
  td {
    background: var(--gray-low);
    height: 70px;
    font-family: var(--font-opensans);
    font-style: normal;
    font-weight: normal;
    font-size: var(--body);
    line-height: 20px;
    color: var(--blue-dark);
    p {
      margin-bottom: 0;
    }
    div {
      margin: 1em 0.5em;
      overflow-y: auto;
      height: 40px;
    }
  }
`;

const TitleStyled = styled.h2`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: var(--subtitle);
  line-height: 27px;
  color: var(--blue-dark);
  margin-left: 0.8em;
  @media (max-width: 991px) {
    margin-top: 1em;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
