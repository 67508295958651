import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

function FilterTabs(props) {
  const { actionTab1, actionTab2, actionTab3, select } = props;
  return (
    <>
      <Filter>
        <ItemFilter
          onClick={actionTab1}
          style={{ textDecorationLine: select === 1 ? "underline" : "none" }}
        >
          Actualizaciones
        </ItemFilter>
        <ItemFilter
          onClick={actionTab2}
          style={{ textDecorationLine: select === 2 ? "underline" : "none" }}
        >
          Equipo
        </ItemFilter>
        <ItemFilter
          onClick={actionTab3}
          style={{ textDecorationLine: select === 3 ? "underline" : "none" }}
        >
          Detalles
        </ItemFilter>
      </Filter>
    </>
  );
}
FilterTabs.propTypes = {
  select: PropTypes.number,
  actionTab1: PropTypes.func,
  actionTab2: PropTypes.func,
  actionTab3: PropTypes.func,
};

export default FilterTabs;
const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0em 1em;
`;
const ItemFilter = styled.div`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: normal;
  font-size: var(--body);
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--blue-dark);
  margin: 0 0.5em;
  cursor: pointer;
`;
