import React, { useRef, useState } from "react";
import { Drawer } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "../Default/Select";
import DatePicker from "../Default/DatePicker";
import Button from "../Default/Button";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  filterByCountry,
  getAllData,
  filterByDate,
  filterBySeason,
  filterByCriticality,
} from "../../redux/problems/problemsDucks";
import axios from "axios";
import config from "../../config";

const criticality = [
  {
    label: "Bajo",
    value: "1",
  },
  {
    label: "Moderado",
    value: "2",
  },
  {
    label: "Alto",
    value: "3",
  },
  {
    label: "Muy alto",
    value: "4",
  },
];
const country = [
  {
    label: "Chile",
    value: "Chile",
  },
  {
    label: "Colombia",
    value: "Colombia",
  },
  {
    label: "Ecuador",
    value: "Ecuador",
  },
];

const seasons = [
  {
    label: "Bogotá",
    value: "Bogota",
  },
  {
    label: "Santiago",
    value: "Santiago",
  },
  {
    label: "Medellin",
    value: "Medellin",
  },
  {
    label: "Quito",
    value: "Quito",
  },
];

function Filter(props) {
  const { visible, onClose } = props;

  const selectCountryRef = useRef();
  const selectSeasonRef = useRef();
  const selectCriticalityRef = useRef();

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedCriticality, setSelectedCriticality] = useState("");
  const [dateCreate, setDateCreate] = useState("");

  const dispacth = useDispatch();

  const clearFilter = async () => {
    selectCountryRef.current.select.clearValue();
    selectCriticalityRef.current.select.clearValue();
    selectSeasonRef.current.select.clearValue();

    setSelectedCountry("");
    setSelectedSeason("");
    setSelectedCriticality("");
    setDateCreate("");
    const resp = await axios.get(`${config.api_sheet}/bd_problemas`);
    dispacth(getAllData(resp.data));
    dispacth(filterByCountry("", false));
    onClose();
  };

  const handleChange = (e, action) => {
    if (e !== null && action === 1) {
      setSelectedCountry(e.value);
    } else if (e !== null && action === 2) {
      setSelectedSeason(e.value);
    } else if (e !== null && action === 3) {
      setSelectedCriticality(e.value);
    }
  };

  const handleChangeDate = (dates, dateStrings) => {
    console.log(dates, dateStrings);
    setDateCreate(moment(dateStrings).format("DD/MM/YYYY"));
  };

  const getValue = (option) => {
    return option.value;
  };
  const getLabel = (option) => {
    return option.label;
  };
  const filter = async () => {
    dispacth(filterByCountry(selectedCountry, true));
    dispacth(filterByCriticality(selectedCriticality));
    dispacth(filterBySeason(selectedSeason));
    dispacth(filterByDate(dateCreate));
    try {
      const resp = await axios.get(
        `${config.api_sheet}/bd_problemas/search?pais=*${selectedCountry}*&criticidad=*${selectedCriticality}*&fecha_creacion=*${dateCreate}*&estacion=*${selectedSeason}`
      );
      dispacth(getAllData(resp.data));
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const title = <TitleStyled>Filtros</TitleStyled>;

  return (
    <>
      <Drawer
        title={title}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={400}
      >
        <Select
          options={country}
          placeholder={"País"}
          useRef={selectCountryRef}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          handleChange={(e) => handleChange(e, 1)}
        />

        <Select
          options={seasons}
          placeholder={"Estación"}
          useRef={selectSeasonRef}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          handleChange={(e) => handleChange(e, 2)}
        />
        <Select
          options={criticality}
          placeholder={"Criticidad"}
          useRef={selectCriticalityRef}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          handleChange={(e) => handleChange(e, 3)}
        />

        <DatePicker
          onChangeDate={handleChangeDate}
          placeholder={"Fecha Creación"}
        />

        <FooterButton>
          <Button
            title="Aplicar filtros"
            background="var(--blue-medium)"
            onClick={filter}
          />
          <Button
            title="Borrar"
            background="var(--danger)"
            onClick={clearFilter}
          />
        </FooterButton>
      </Drawer>
    </>
  );
}

Filter.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Filter;

const TitleStyled = styled.h1`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: var(--title);
  line-height: 41px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: var(--blue-dark);
`;

const FooterButton = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 18px;
  left: 18px;
  margin: auto;
  bottom: 18px;
`;
