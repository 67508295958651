import React from "react";
import Chart from "react-google-charts";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { selectId } from "../../redux/roadmap/roadmapDucks";

const Gantt = ({ data }) => {
  const dispatch = useDispatch();
  const rows = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
  ];

  return (
    <GanttComponent>
      <Chart
        width={"100%"}
        chartType="Gantt"
        loader={<div>Loading Chart</div>}
        options={{
          height: data.length * 63,
          gantt: {
            trackHeight: 60,
            criticalPathEnabled: false,
            innerGridHorizLine: {
              stroke: "#FFF",
              strokeWidth: 5,
            },
            innerGridTrack: { fill: "#F3F3F3" },
            innerGridDarkTrack: { fill: "#F3F3F3" },
            arrow: {
              width: 2,
              color: "#000",
              radius: 2,
            },
          },
        }}
        data={[rows, ...data]}
        rootProps={{ "data-testid": "1" }}
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart().getSelection()[0];
              const dataTable = chartWrapper.getDataTable();
              dispatch(selectId(dataTable.getValue(chart.row, 0)));
            },
          },
        ]}
      />
    </GanttComponent>
  );
};

Gantt.propTypes = {
  data: PropTypes.array,
};
export default Gantt;

const GanttComponent = styled.div`
svg {
  // Background Color
  g:nth-child(2) { 
    rect {
      fill: white;
    } 
  }
  // Side Labels
  g:nth-child(9) {
    text {
      fill: var(--blue-dark);
      transform: translate(20em, 0px);
      font-family: var(--fonts-opensans) !important;   
      cursor:pointer !important;
  
    }
  }
  g:nth-child(10) {
    display:none;
    }
  }
  // Chart Settings
  g:nth-child(3) {
    text { 
      // Horizontal Labels
      fill:var(--blue-dark);
      font-family: var(--fonts-opensans) !important;     
      font-weight: 600 !important;

    } 
  }
  // Shadow
  g:nth-child(6) {
    rect {
      fill: none;
    }
  }
  // Bars
  g:nth-child(7) {
    cursor:pointer;
    rect {
      fill: #2BB0FF;
      transform: translate(24em, 0px)
    }
  }
  // Percent Complete
  g:nth-child(8) {
    path {
      fill: var(--blue-dark);
  }
 
`;
