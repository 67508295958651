import React from "react";
import styled from "styled-components";

import axios from "axios";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, message } from "antd";
import moment from "moment";
import Select from "../Default/Select";
import { getAllData } from "../../redux/problems/problemsDucks";

const { TextArea } = Input;

import Button from "../Default/Button";
import config from "../../config";

const status = [
  {
    label: "Bajo",
    value: "Bajo",
  },
  {
    label: "Moderado",
    value: "Moderado",
  },
  {
    label: "Alto",
    value: "Alto",
  },
  {
    label: "Muy alto",
    value: "Muy alto",
  },
];

const countryOptions = [
  {
    label: "Chile",
    value: "Chile",
  },
  {
    label: "Colombia",
    value: "Colombia",
  },
  {
    label: "Ecuador",
    value: "Ecuador",
  },
];

const seasons = [
  {
    label: "Bogotá",
    value: "Bogota",
  },
  {
    label: "Santiago",
    value: "Santiago",
  },
  {
    label: "Medellin",
    value: "Medellin",
  },
  {
    label: "Quito",
    value: "Quito",
  },
];
const typeProblem = [
  {
    label: "Falta de estándar",
    value: "a",
  },
  {
    label: "Desviación al estándar",
    value: "b",
  },
  {
    label: "Mejora al estándar",
    value: "c",
  },
  {
    label: "Cambio de estándar",
    value: "d",
  },
];
function FormComponent(props) {
  const { drawerOnClose } = props;
  const dispacth = useDispatch();

  const [criticality, setCriticality] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [season, setSeason] = React.useState("");
  const [problemType, setProblemType] = React.useState("");

  const [datos, setDatos] = React.useState({
    titulo: "",
    descripcion: "",
    area_apoyo: "",
    levantado_por: "",
  });

  const selectCriticalityRef = React.useRef();
  const selectCountryRef = React.useRef();
  const selectSeasonRef = React.useRef();
  const selectProblemTypeRef = React.useRef();

  const [form] = Form.useForm();

  const allDataProblems = useSelector((store) => store.problem.data);

  const maxFilter = Math.max(...allDataProblems.map((el) => el.id));

  React.useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const response = await axios.get(`${config.api_sheet}/bd_problemas`);
    dispacth(getAllData(response.data));
  };

  const handleInputChange = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };

  const sendData = async () => {
    if (datos.titulo === "" || datos.titulo === null) {
      message.info("Por favor ingrese el titulo");
    } else {
      const obj = {
        id: maxFilter + 1,
        titulo: datos.titulo,
        descripcion: datos.descripcion,
        tipo_problema: problemType,
        area_apoyo: datos.area_apoyo,
        levantado_por: datos.levantado_por,
        criticidad: criticality,
        season: season,
        country: country,
        fecha_creacion: moment(new Date()).format("DD/MM/YYYY"),
      };
      console.log(obj);
      try {
        const response = await axios.post(
          `${config.api_sheet}/bd_problemas`,
          obj
        );
        if (response) {
          drawerOnClose();
          clearValues();
          getData();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const clearValues = () => {
    if (
      selectCriticalityRef.current !== null &&
      selectCriticalityRef.current !== undefined
    ) {
      selectCriticalityRef.current.select.clearValue();
    } else if (
      selectCountryRef.current !== null &&
      selectCountryRef.current !== undefined
    ) {
      selectCountryRef.current.select.clearValue();
    } else if (
      selectSeasonRef.current !== null &&
      selectSeasonRef.current !== undefined
    ) {
      selectSeasonRef.current.select.clearValue();
    } else if (
      selectProblemTypeRef.current !== null &&
      selectProblemTypeRef.current !== undefined
    ) {
      selectProblemTypeRef.current.select.clearValue();
    }

    setCriticality("");
    setSeason("");
    setCountry("");
    setProblemType("");
    setDatos({
      titulo: "",
      descripcion: "",
      area_apoyo: "",
      levantado_por: "",
    });
  };

  const handleChange = (e, action) => {
    if (e !== null && action === 1) {
      setCriticality(e.value);
    } else if (e !== null && action === 2) {
      setCountry(e.value);
    } else if (e !== null && action === 3) {
      setSeason(e.value);
    } else if (e !== null && action === 4) {
      setProblemType(e.value);
    }
  };
  const getValue = (option) => {
    return option.value;
  };
  const getLabel = (option) => {
    return option.label;
  };
  return (
    <Wrapper>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={sendData}
        layout={"vertical"}
        form={form}
      >
        <Content>
          <Form.Item>
            <Input
              placeholder="Título"
              bordered={false}
              onChange={handleInputChange}
              value={datos.titulo}
              name={"titulo"}
            />
          </Form.Item>
          <Form.Item>
            <TextArea
              placeholder="Descripción"
              bordered={false}
              rows={4}
              onChange={handleInputChange}
              value={datos.descripcion}
              name="descripcion"
            />
          </Form.Item>
          <Select
            options={typeProblem}
            placeholder={"Clase de problema"}
            useRef={selectProblemTypeRef}
            handleChange={(e) => handleChange(e, 4)}
            getOptionLabel={getLabel}
            getOptionValue={getValue}
          />

          <Form.Item>
            <Input
              placeholder="Área de impacto"
              bordered={false}
              onChange={handleInputChange}
              value={datos.area_apoyo}
              name="area_apoyo"
            />
          </Form.Item>
          <Form.Item>
            <Input
              placeholder="Levantado por:"
              bordered={false}
              onChange={handleInputChange}
              value={datos.levantado_por}
              name="levantado_por"
            />
          </Form.Item>

          <Select
            options={status}
            placeholder={"Criticidad"}
            useRef={selectCriticalityRef}
            handleChange={(e) => handleChange(e, 1)}
            getOptionLabel={getLabel}
            getOptionValue={getValue}
          />
          <Select
            options={countryOptions}
            placeholder={"Pais"}
            useRef={selectCountryRef}
            handleChange={(e) => handleChange(e, 2)}
            getOptionLabel={getLabel}
            getOptionValue={getValue}
          />
          <Select
            options={seasons}
            placeholder={"Estación"}
            useRef={selectSeasonRef}
            handleChange={(e) => handleChange(e, 3)}
            getOptionLabel={getLabel}
            getOptionValue={getValue}
          />
        </Content>

        <FooterButton>
          <Button
            htmlType="submit"
            background="var(--blue-medium)"
            title={"Crear Problema"}
          />
          <Button
            onClick={clearValues}
            background="var(--danger)"
            title={"Borrar"}
          />
        </FooterButton>
      </Form>
    </Wrapper>
  );
}

FormComponent.propTypes = {
  drawerOnClose: PropTypes.func,
};
export default FormComponent;

const Wrapper = styled.div`
  .ant-space-vertical {
    background: var(--gray-low);
    width: 100%;
    border-radius: 5px;
    margin: 0 0 1.5em 0;
  }
  .ant-picker {
    width: 100%;
    border: none !important;
    background: var(--gray-low);
    height: 52px;
    border-radius: 5px;
  }
  .ant-picker-input > input {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: var(--subtitle);
    line-height: 23px;
    letter-spacing: 0.05em;
  }
  .ant-picker-input > input::placeholder {
    color: #8a8a8a;
  }
  .ant-select-selection__placeholder {
    color: blue;
  }
`;

const Content = styled.div`
  input,
  textarea {
    width: 100%;
    height: 52px;
    background: var(--gray-low) !important;
    border-radius: 5px;
    border: none;
    padding: 0.5em;
    outline: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: var(--subtitle);
    letter-spacing: 0.05em;
    color: var(--gray);
  }
  textarea {
    resize: none;
    height: 8em;
  }
  .ant-form-item {
    margin-bottom: 15px;
  }
`;

const FooterButton = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  width: 96%;
  left: 10px;
  right: 10px;
  button {
    display: grid;
    width: 100%;
  }
`;
