import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Header from "../components/Default/Header";
import ContainerCounter from "../components/ContainerCounter/ContainerCounter";
import ChartPortfolio from "../components/ChartPortfolio/index";
import TableComponent from "../components/Table/Table";
import DrawerComponent from "../components/Drawer/Drawer";
import FormInitiatives from "../components/Initiative/Form";
import Filter from "../components/Filter/Filter";

import svgPortfolio from "../assets/images/portfolio.svg";
import { getAllData } from "../redux/initiative/initiativeDucks";
import config from "../config";

function Portfolio() {
  const allDataInitiatives = useSelector((store) => store.initiative.data);

  const activeFilter = useSelector((store) => store.initiative.activeFilter);
  const dataByCategory = useSelector(
    (store) => store.initiative.dataByCategory
  );

  const [visible, setVisible] = React.useState(false);
  const [visibleFilter, setVisibleFilter] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const resp = await axios.get(`${config.api_sheet}/base_2021`);
    dispatch(getAllData(resp.data));
  };

  //Total de counter
  const totalBenefCapt = () => {
    let acum = allDataInitiatives.reduce(
      (accu, currentValue) => accu - -currentValue.benef_captura,
      0
    );
    return parseInt(acum);
  };
  const totalBenefCompr = () => {
    let acum = allDataInitiatives.reduce(
      (accu, currentValue) => accu - -currentValue.benef_compr,
      0
    );
    return parseInt(acum);
  };

  const totalMetaAnio = () => {
    let number =
      allDataInitiatives.length > 0
        ? Math.max(...allDataInitiatives.map((el) => el.meta_benef_año))
        : 0;
    return number;
  };

  const totalMetaByCountry = () => {
    let number =
      allDataInitiatives.length > 0
        ? Math.max(...allDataInitiatives.map((el) => el.meta_pais))
        : 0;
    return number;
  };

  //Total promedio real / plan
  const totalAvancePercent = () => {
    let prome = totalBenefCapt()
      ? (totalBenefCapt() / totalMetaAnio()) * 100
      : 0;
    return prome;
  };

  //Total promedio proyectado / plan
  const totalProyecPercent = () => {
    let prome = totalBenefCompr()
      ? (totalBenefCompr() / totalMetaAnio()) * 100
      : 0;
    return prome;
  };

  const validateByFilterCountry =
    activeFilter === true ? totalMetaByCountry() : totalMetaAnio();

  const data = [
    {
      title: "Meta WAVE",
      counter:
        validateByFilterCountry > 0
          ? validateByFilterCountry.toLocaleString()
          : 0,
    },
    {
      title: "Capturado",
      counter: totalBenefCapt() > 0 ? totalBenefCapt().toLocaleString() : 0,
    },
    {
      title: "% Capturado",
      counter: `${totalAvancePercent().toFixed(0)} %`,
    },
    {
      title: "Proyectado",
      counter: totalBenefCompr() > 0 ? totalBenefCompr().toLocaleString() : 0,
    },
    {
      title: "% Proyectado",
      counter: `${
        totalProyecPercent() > 0 ? totalProyecPercent().toFixed(0) : 0
      } %`,
    },
  ];

  const titleHeader = [
    {
      title: "#",
      width: 10,
    },
    {
      title: "Título",
      width: 20,
    },
    {
      title: "Beneficio",
      width: 20,
    },
    {
      title: "Ultima revisión",
      width: 30,
    },
  ];
  const showDrawer = (action) => {
    action === 1 ? setVisible(true) : setVisibleFilter(true);
  };
  const onClose = () => {
    setVisibleFilter(false);
  };

  const onCloseForm = () => {
    setVisible(false);
  };
  const drawer = (
    <DrawerComponent
      title={"Nueva Iniciativa"}
      placement="right"
      closable={false}
      onClose={onCloseForm}
      visible={visible}
      width={400}
    >
      <FormInitiatives drawerOnClose={onCloseForm} />
    </DrawerComponent>
  );

  const drawerFilter = <Filter onClose={onClose} visible={visibleFilter} />;

  return (
    <>
      {drawer}
      {drawerFilter}
      <Header
        title="Portafolio"
        icon={svgPortfolio}
        btnLogout
        btnFilter
        btnNewItem
        titleBtnNewItem={"Nueva Iniciativa"}
        drawerShowForm={() => showDrawer(1)}
        drawerShowFilter={() => showDrawer(2)}
      />
      <ContainerCounter data={data} />
      <Row>
        <Col className="col-one">
          <ChartPortfolio />
        </Col>
        <Col className="col-two">
          <TableComponent
            titleHeader={titleHeader}
            dataTable={
              dataByCategory.length > 0 ? dataByCategory : allDataInitiatives
            }
            height={685}
          />
        </Col>
      </Row>
    </>
  );
}

export default Portfolio;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 992px) {
    .col-one {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .col-two {
      grid-column-start: 2;
      grid-column-end: 5;
    }
  }
`;
const Col = styled.div``;
