import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { getAllData } from "../redux/problems/problemsDucks";
import config from "../config";
import Header from "../components/Default/Header";
import svgProblems from "../assets/images/problems.svg";
import Item from "../components/Default/Item";
import TableProccess from "../components/Table/TableProcess";
import Table from "../components/Problems/Table";
import Drawer from "../components/Drawer/Drawer";
import Form from "../components/Problems/Form";
import Filter from "../components/Filter/FilterProblem";
import ChartTinly from "../components/Chart/TinlyLine";

function Problems() {
  const [visible, setVisible] = React.useState(false);
  const [dataGraphic, setDataGraphic] = React.useState([]);
  const [visibleFilter, setVisibleFilter] = React.useState(false);

  const dataByOpeAndTypes = useSelector(
    (store) => store.problem.dataByOpeAndTypes
  );
  const allProblems = useSelector((store) => store.problem.data);
  const dispacth = useDispatch();

  //By Type problem
  const typeProblemByA = allProblems.filter((el) => el.tipo_problema === "a");
  const typeProblemByB = allProblems.filter((el) => el.tipo_problema === "b");
  const typeProblemByC = allProblems.filter((el) => el.tipo_problema === "c");
  const typeProblemByD = allProblems.filter((el) => el.tipo_problema === "d");

  //All tipo problems
  const allTypeProblems = allProblems.filter(
    (el) =>
      el.tipo_problema === "a" ||
      el.tipo_problema === "b" ||
      el.tipo_problema === "c" ||
      el.tipo_problema === "d"
  );

  //Problems abordados
  const initiativeProblemByA = typeProblemByA.filter((el) => el.iniciativa_id);
  const initiativeProblemByB = typeProblemByB.filter((el) => el.iniciativa_id);
  const initiativeProblemByC = typeProblemByC.filter((el) => el.iniciativa_id);
  const initiativeProblemByD = typeProblemByD.filter((el) => el.iniciativa_id);

  //Total problems by abordados
  const allInitiativesProblems = allProblems.filter((el) => el.iniciativa_id);

  //percentage gestion
  const percentageByA = (
    (initiativeProblemByA.length > 0
      ? initiativeProblemByA.length / typeProblemByA.length
      : 0) * 100
  ).toFixed(1);

  const percentageByB = (
    (initiativeProblemByB.length > 0
      ? initiativeProblemByB.length / typeProblemByB.length
      : 0) * 100
  ).toFixed(1);

  const percentageByC = (
    (initiativeProblemByC.length > 0
      ? initiativeProblemByC.length / typeProblemByC.length
      : 0) * 100
  ).toFixed(1);

  const percentageByD = (
    (initiativeProblemByD.length > 0
      ? initiativeProblemByD.length / typeProblemByD.length
      : 0) * 100
  ).toFixed(1);

  //Total percentage
  const totalPercentage = (
    (allInitiativesProblems.length > 0
      ? allInitiativesProblems.length / allTypeProblems.length
      : 0) * 100
  ).toFixed(2);

  React.useEffect(() => {
    getData();
    getDataGraphic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const response = await axios.get(`${config.api_sheet}/bd_problemas`);
    dispacth(getAllData(response.data));
  };
  const getDataGraphic = async () => {
    const response = await axios.get(`${config.api_sheet}/grafico`);
    setDataGraphic(response.data);
  };

  const dataFilterGraphic = dataGraphic.map((el) => ` ${el.week} ${el.perc}`);
  const titleHeader = [
    {
      title: "Problema",
      width: 35,
    },
    {
      title: "Proceso / Subproceso",
      width: 25,
    },
    {
      title: "Iniciativa",
      width: 20,
    },
    {
      title: "Dueño",
      width: 20,
    },
  ];

  const showDrawer = (action) => {
    action === 1 ? setVisible(true) : setVisibleFilter(true);
  };
  const onClose = (action) => {
    action.detail === 1 ? setVisible(false) : setVisibleFilter(false);
  };
  const drawer = (
    <Drawer
      title={"Nuevo Problema"}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={400}
    >
      <Form drawerOnClose={() => onClose(1)} />
    </Drawer>
  );
  const drawerFilter = (
    <Filter onClose={() => onClose(2)} visible={visibleFilter} />
  );
  return (
    <>
      {drawer}
      {drawerFilter}
      <Header
        title="Problemas"
        icon={svgProblems}
        btnLogout
        btnFilter
        btnNewItem
        titleBtnNewItem={"Nuevo Problema"}
        drawerShowForm={() => showDrawer(1)}
        drawerShowFilter={() => showDrawer(2)}
      />
      <Row style={{ margin: "1.5em 0" }}>
        <Col>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TitleStyled>N° de problemas</TitleStyled>
            <Counter>{allTypeProblems.length}</Counter>
          </div>
          <Item backgroundColor={"var(--gray-medium)"} height={140}>
            <BodyStyled>
              <p>Falta al estándar</p>
              <p>
                <span> {typeProblemByA.length} </span>
              </p>
            </BodyStyled>
            <BodyStyled>
              <p>Desviación al estándar</p>
              <p>
                <span> {typeProblemByB.length} </span>
              </p>
            </BodyStyled>
            <BodyStyled>
              <p>Mejora al estándar</p>
              <p>
                <span> {typeProblemByC.length} </span>
              </p>
            </BodyStyled>
            <BodyStyled>
              <p>Cambio de estándar</p>
              <p>
                <span> {typeProblemByD.length}</span>
              </p>
            </BodyStyled>
          </Item>
        </Col>
        <Col>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TitleStyled>N° de problemas abordados</TitleStyled>
            <Counter>{allInitiativesProblems.length}</Counter>
          </div>
          <Item backgroundColor={"var(--gray-medium)"} height={140}>
            <BodyStyled>
              <p>Falta al estándar</p>
              <p>
                <span> {initiativeProblemByA.length} </span>
              </p>
            </BodyStyled>
            <BodyStyled>
              <p>Desviación al estándar</p>
              <p>
                <span> {initiativeProblemByB.length} </span>
              </p>
            </BodyStyled>
            <BodyStyled>
              <p>Mejora al estándar</p>
              <p>
                <span> {initiativeProblemByC.length} </span>
              </p>
            </BodyStyled>
            <BodyStyled>
              <p>Cambio de estándar</p>
              <p>
                <span> {initiativeProblemByD.length}</span>
              </p>
            </BodyStyled>
          </Item>
        </Col>
        <Col>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TitleStyled>% de gestión</TitleStyled>
            <Counter>{totalPercentage}%</Counter>
          </div>
          <Item backgroundColor={"var(--gray-medium)"} height={140}>
            <BodyStyled>
              <p>Falta al estándar</p>
              <p>
                <span> {percentageByA}% </span>
              </p>
            </BodyStyled>
            <BodyStyled>
              <p>Desviación al estándar</p>
              <p>
                <span> {percentageByB}% </span>
              </p>
            </BodyStyled>
            <BodyStyled>
              <p>Mejora al estándar</p>
              <p>
                <span> {percentageByC}% </span>
              </p>
            </BodyStyled>
            <BodyStyled>
              <p>Cambio de estándar</p>
              <p>
                <span> {percentageByD}%</span>
              </p>
            </BodyStyled>
          </Item>
        </Col>
        <Col>
          <TitleStyled>Evolución</TitleStyled>
          <Item backgroundColor={"var(--gray-medium)"} height={140}>
            <BodyChart>
              <ChartTinly data={dataFilterGraphic} />
            </BodyChart>
          </Item>
        </Col>
      </Row>
      <RowTable>
        <Col style={{ display: "grid", position: "relative" }}>
          <TableProccess />
        </Col>
        <Col>
          <Table
            dataTableHeader={titleHeader}
            dataTable={dataByOpeAndTypes.length > 0 ? dataByOpeAndTypes : []}
            titleTable="Lista de problemas"
          />
        </Col>
      </RowTable>
    </>
  );
}

export default Problems;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const RowTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1100px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const Col = styled.div`
  margin: 5px;
  font-family: Open Sans;
  line-height: 20px;
  color: var(--blue-dark);
  font-style: normal;
  position: relative;
`;
const TitleStyled = styled.h1`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: var(--subtitle);
  color: var(--blue-dark);
`;

const BodyChart = styled.div`
  font-size: var(--body-big);
  font-weight: bold;
  line-height: 68px;
  text-align: center;
  position: absolute;
  top: 1em;
  bottom: 0.5em;
  left: 0;
  right: 0;
`;

const BodyStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0.5em;
  position: relative;
  line-height: 2em;
  padding: 0.2em;
  p {
    font-size: var(--body);
    margin-bottom: 0;
  }
  span {
    font-weight: bold;
    font-size: var(--subtitle);
  }
`;

const Counter = styled.div`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: var(--subtitle);
  color: var(--blue-dark);
`;
