/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";

const up = (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.59 7.40991L6 2.82991L1.41 7.40991L0 5.99991L6 -8.7738e-05L12 5.99991L10.59 7.40991Z"
      fill="#C4C4C4"
    />
  </svg>
);

const down = (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z"
      fill="#C4C4C4"
    />
  </svg>
);
const Strategy = ({ getStrategy }) => {
  const [count, setCount] = useState(5);

  useEffect(() => {
    getStrategy(count);
  }, []);

  const formStrategy = (numb) => {
    let strategy = 0;
    switch (numb) {
      case 3:
        strategy = 3 - 1;
        break;
      case 5:
        strategy = 6 - 1;
        break;
      case 7:
        strategy = 9 - 1;
        break;
      default:
        break;
    }
    getStrategy(strategy);
  };

  const upCount = (value) => {
    if (value <= 5) {
      setCount(value + 2);
      formStrategy(value + 2);
    }
  };
  const downCount = (value) => {
    if (value >= 5) {
      setCount(value - 2);
      formStrategy(value - 2);
    }
  };

  return (
    <Wrapper
      style={{
        marginBottom: 32,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          fontWeight: "500",
        }}
      >
        <div
          style={{
            fontFamily: "var(--font-opensans)",
            fontSize: "var(--body)",
            color: "var(--blue-dark)",
            fontWeight: "400",
          }}
        >
          Estrategia a
        </div>
        <div
          style={{
            color: "var(--blue-dark)",
            margin: " 0 0.2em",
          }}
        >
          {count}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: -10,
          }}
        >
          <Button
            onClick={() => upCount(count)}
            type="text"
            icon={up}
            size="small"
          />

          <Button
            onClick={() => downCount(count)}
            type="text"
            icon={down}
            size="small"
          />
        </div>
        <div
          style={{
            fontFamily: "var(--font-opensans)",
            fontSize: "var(--body)",
            color: "var(--blue-dark)",
            fontWeight: "bold",
            margin: " 0 0.2em",
          }}
        >
          años
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-btn-icon-only.ant-btn-sm {
    width: 15px;
    height: 15px;
  }
`;
Strategy.propTypes = {
  getStrategy: PropTypes.any,
};
export default Strategy;
