import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import FilterComponent from "../Problems/Filter";
import {
  totalByTypeA,
  totalByTypeB,
  filterByOperationsAndTypes,
  totalByTypeC,
  totalByTypeD,
  totalByTypes,
  activeTypeByA,
  activeTypeByB,
  activeTypeByC,
  activeTypeByD,
  activeByTypes,
} from "../../redux/problems/problemsDucks";

function TableProcess() {
  const allProblems = useSelector((store) => store.problem.data);
  const totalTypeA = useSelector((store) => store.problem.totalTypeA);
  const totalTypeB = useSelector((store) => store.problem.totalTypeB);
  const totalTypeC = useSelector((store) => store.problem.totalTypeC);
  const totalTypeD = useSelector((store) => store.problem.totalTypeD);
  const totalTypes = useSelector((store) => store.problem.totalTypes);
  const activeTypeA = useSelector((store) => store.problem.activeTypeA);
  const activeTypeB = useSelector((store) => store.problem.activeTypeB);
  const activeTypeC = useSelector((store) => store.problem.activeTypeC);
  const activeTypeD = useSelector((store) => store.problem.activeTypeD);
  const activeTypes = useSelector((store) => store.problem.activeTypes);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      totalByTypeA([
        {
          id: 1,
          total: allProblems.filter(
            (el) => el.tipo_problema === "a" && el.operacion === "Trafico"
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) => el.tipo_problema === "a" && el.operacion === "Rampa"
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) => el.tipo_problema === "a" && el.operacion === "Importaciones"
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) => el.tipo_problema === "a" && el.operacion === "Exportaciones"
          ),
        },
        {
          id: 5,
          total: allProblems.filter((el) => el.tipo_problema === "a"),
        },
      ])
    );
    dispatch(
      totalByTypeB([
        {
          id: 1,
          total: allProblems.filter(
            (el) => el.tipo_problema === "b" && el.operacion === "Trafico"
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) => el.tipo_problema === "b" && el.operacion === "Rampa"
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) => el.tipo_problema === "b" && el.operacion === "Importaciones"
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) => el.tipo_problema === "b" && el.operacion === "Exportaciones"
          ),
        },
        {
          id: 5,
          total: allProblems.filter((el) => el.tipo_problema === "b"),
        },
      ])
    );
    dispatch(
      totalByTypeC([
        {
          id: 1,
          total: allProblems.filter(
            (el) => el.tipo_problema === "c" && el.operacion === "Trafico"
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) => el.tipo_problema === "c" && el.operacion === "Rampa"
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) => el.tipo_problema === "c" && el.operacion === "Importaciones"
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) => el.tipo_problema === "c" && el.operacion === "Exportaciones"
          ),
        },
        {
          id: 5,
          total: allProblems.filter((el) => el.tipo_problema === "c"),
        },
      ])
    );
    dispatch(
      totalByTypeD([
        {
          id: 1,
          total: allProblems.filter(
            (el) => el.tipo_problema === "d" && el.operacion === "Trafico"
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) => el.tipo_problema === "d" && el.operacion === "Rampa"
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) => el.tipo_problema === "d" && el.operacion === "Importaciones"
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) => el.tipo_problema === "d" && el.operacion === "Exportaciones"
          ),
        },
        {
          id: 5,
          total: allProblems.filter((el) => el.tipo_problema === "d"),
        },
      ])
    );
    dispatch(
      totalByTypes([
        {
          id: 1,
          total: allProblems.filter(
            (el) => el.tipo_problema && el.operacion === "Trafico"
          ),
        },
        {
          id: 2,
          total: allProblems.filter(
            (el) => el.tipo_problema && el.operacion === "Rampa"
          ),
        },
        {
          id: 3,
          total: allProblems.filter(
            (el) => el.tipo_problema && el.operacion === "Importaciones"
          ),
        },
        {
          id: 4,
          total: allProblems.filter(
            (el) => el.tipo_problema && el.operacion === "Exportaciones"
          ),
        },
        {
          id: 5,
          total: allProblems.filter((el) => el.tipo_problema),
        },
      ])
    );
  }, [allProblems]);

  //Header grid render
  const titleHeaderGrid = [
    {
      title: "Operación",
    },
    {
      title: "Falta de estándar",
    },
    {
      title: "Desviación al estándar",
    },
    {
      title: "Mejora al estándar",
    },
    {
      title: "Cambio de estándar",
    },
    {
      title: "Totales",
    },
  ];
  //Options op render
  const op = [
    {
      title: "Tráfico",
    },
    {
      title: "Rampa",
    },
    {
      title: "Importaciones",
    },
    {
      title: "Exportaciones",
    },
    {
      title: "Totales",
    },
  ];

  //Push data to table problems por tipo a
  const pushInitiativeTypeA = (id) => {
    if (id === 1) {
      totalTypeA
        .filter((el) => el.id === 1)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByA(id));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
      dispatch(activeByTypes(null));
    } else if (id === 2) {
      totalTypeA
        .filter((el) => el.id === 2)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByA(id));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
      dispatch(activeByTypes(null));
    } else if (id === 3) {
      totalTypeA
        .filter((el) => el.id === 3)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByA(id));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
      dispatch(activeByTypes(null));
    } else if (id === 4) {
      totalTypeA
        .filter((el) => el.id === 4)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByA(id));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
      dispatch(activeByTypes(null));
    } else if (id === 5) {
      totalTypeA
        .filter((el) => el.id === 5)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByA(id));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
      dispatch(activeByTypes(null));
    }
  };

  //Push data to table problems por tipo b
  const pushInitiativeTypeB = (id) => {
    if (id === 1) {
      totalTypeB
        .filter((el) => el.id === 1)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByB(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
      dispatch(activeByTypes(null));
    } else if (id === 2) {
      totalTypeB
        .filter((el) => el.id === 2)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByB(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
      dispatch(activeByTypes(null));
    } else if (id === 3) {
      totalTypeB
        .filter((el) => el.id === 3)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByB(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
      dispatch(activeByTypes(null));
    } else if (id === 4) {
      totalTypeB
        .filter((el) => el.id === 4)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByB(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
      dispatch(activeByTypes(null));
    } else if (id === 5) {
      totalTypeB
        .filter((el) => el.id === 5)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByB(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
      dispatch(activeByTypes(null));
    }
  };

  //Push data to table problems por tipo c
  const pushInitiativeTypeC = (id) => {
    if (id === 1) {
      totalTypeC
        .filter((el) => el.id === 1)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByC(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByD(null));
      dispatch(activeByTypes(null));
    } else if (id === 2) {
      totalTypeC
        .filter((el) => el.id === 2)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByC(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByD(null));
    } else if (id === 3) {
      totalTypeC
        .filter((el) => el.id === 3)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByC(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByD(null));
    } else if (id === 4) {
      totalTypeC
        .filter((el) => el.id === 4)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByC(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByD(null));
    } else if (id === 5) {
      totalTypeC
        .filter((el) => el.id === 5)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByC(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByD(null));
    }
  };
  //Push data to table problems por tipo d
  const pushInitiativeTypeD = (id) => {
    if (id === 1) {
      totalTypeD
        .filter((el) => el.id === 1)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByD(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeByTypes(null));
    } else if (id === 2) {
      totalTypeD
        .filter((el) => el.id === 2)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByD(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeByTypes(null));
    } else if (id === 3) {
      totalTypeD
        .filter((el) => el.id === 3)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByD(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeByTypes(null));
    } else if (id === 4) {
      totalTypeD
        .filter((el) => el.id === 4)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByD(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeByTypes(null));
    } else if (id === 5) {
      totalTypeD
        .filter((el) => el.id === 5)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeTypeByD(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeByTypes(null));
    }
  };
  //Push data to table problems por tipo all
  const pushInitiativeTypes = (id) => {
    if (id === 1) {
      totalTypes
        .filter((el) => el.id === 1)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeByTypes(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
    } else if (id === 2) {
      totalTypes
        .filter((el) => el.id === 2)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeByTypes(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
    } else if (id === 3) {
      totalTypes
        .filter((el) => el.id === 3)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeByTypes(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
    } else if (id === 4) {
      totalTypes
        .filter((el) => el.id === 4)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeByTypes(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
    } else if (id === 5) {
      totalTypes
        .filter((el) => el.id === 5)
        .map((x) => {
          dispatch(filterByOperationsAndTypes(x.total));
        });
      dispatch(activeByTypes(id));
      dispatch(activeTypeByA(null));
      dispatch(activeTypeByB(null));
      dispatch(activeTypeByC(null));
      dispatch(activeTypeByD(null));
    }
  };
  return (
    <>
      <HeaderStyled>
        <TitleStyled>Procesos críticos</TitleStyled>
        <FilterComponent />
      </HeaderStyled>
      <Wrapper>
        {titleHeaderGrid.map((el, i) => {
          return <Grid key={i}>{el.title}</Grid>;
        })}
        <>
          <VerticalGrids>
            {op.map((el, i) => {
              return (
                <Grid style={{ marginBottom: "0.8em" }} key={i}>
                  {el.title}
                </Grid>
              );
            })}
          </VerticalGrids>
          <VerticalGrids>
            {totalTypeA.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "1em",
                    fontSize: "var(--body)",
                    backgroundColor:
                      activeTypeA === el.id
                        ? "var(--blue-medium)"
                        : el.id !== 5
                        ? "var(--gray-low)"
                        : "var(--gray-medium)",
                    color: activeTypeA === el.id ? "white" : "var(--blue-dark)",
                  }}
                  key={i}
                  onClick={() => pushInitiativeTypeA(el.id)}
                >
                  {el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
          <VerticalGrids>
            {totalTypeB.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "1em",
                    fontSize: "var(--body)",
                    backgroundColor:
                      activeTypeB === el.id
                        ? "var(--blue-medium)"
                        : el.id !== 5
                        ? "var(--gray-low)"
                        : "var(--gray-medium)",
                    color: activeTypeB === el.id ? "white" : "var(--blue-dark)",
                  }}
                  key={i}
                  onClick={() => pushInitiativeTypeB(el.id)}
                >
                  {el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
          <VerticalGrids>
            {totalTypeC.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "1em",
                    fontSize: "var(--body)",
                    backgroundColor:
                      activeTypeC === el.id
                        ? "var(--blue-medium)"
                        : el.id !== 5
                        ? "var(--gray-low)"
                        : "var(--gray-medium)",
                    color: activeTypeC === el.id ? "white" : "var(--blue-dark)",
                  }}
                  key={i}
                  onClick={() => pushInitiativeTypeC(el.id)}
                >
                  {el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
          <VerticalGrids>
            {totalTypeD.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "1em",
                    fontSize: "var(--body)",
                    backgroundColor:
                      activeTypeD === el.id
                        ? "var(--blue-medium)"
                        : el.id !== 5
                        ? "var(--gray-low)"
                        : "var(--gray-medium)",
                    color: activeTypeD === el.id ? "white" : "var(--blue-dark)",
                  }}
                  onClick={() => pushInitiativeTypeD(el.id)}
                  key={i}
                >
                  {el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
          <VerticalGrids>
            {totalTypes.map((el, i) => {
              return (
                <Grid
                  style={{
                    marginBottom: "1em",
                    fontSize: "var(--body)",
                    backgroundColor:
                      activeTypes === el.id
                        ? "var(--blue-medium)"
                        : "var(--gray-medium)",
                    color: activeTypes === el.id ? "white" : "var(--blue-dark)",
                  }}
                  key={i}
                  onClick={() => pushInitiativeTypes(el.id)}
                >
                  {el.total.length}
                </Grid>
              );
            })}
          </VerticalGrids>
        </>
      </Wrapper>
    </>
  );
}

export default TableProcess;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
  overflow-x: auto;
  overflow-y: hidden;
 }
`;

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TitleStyled = styled.h2`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: var(--subtitle);
  line-height: 27px;
  color: var(--blue-dark);
  @media (max-width: 991px) {
    margin-top: 1em;
  }
`;
const Grid = styled.div`
  background: var(--gray-medium);
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: var(--subtitle);
  text-align: center;
  color: var(--blue-dark);
  cursor: pointer;
  height: 70px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0 5px;
`;
const VerticalGrids = styled.div``;
