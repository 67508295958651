import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { selectId, selectIdItemMenu } from "../../redux/roadmap/roadmapDucks";

const FilterRoadmap = () => {
  const dispatch = useDispatch();
  const [nameSelect, setNameSelect] = useState("Iniciativas");

  const menuData = [
    {
      id: 1,
      name: "Iniciativas",
    },
    {
      id: 2,
      name: "Programas",
    },
  ];

  const selectItemMenu = (data) => {
    setNameSelect(data.name);
    dispatch(selectIdItemMenu(data.id));
    dispatch(selectId(""));
  };
  const menu = (
    <Menu>
      {menuData.map((type) => (
        <Menu.Item
          key={type.id}
          onClick={() => selectItemMenu(type)}
          style={{
            height: 26,
            display: "flex",
            alignItems: "center",
            color: "var(--gray-dark)",
            lineHeight: "0.05em",
            fontWeight: "400",
            cursor: "pointer",
            fontSize: "var(--body)",
            fontFamily: "var(--font-opensans)",
          }}
        >
          {type.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <TitleLinkStyled
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          {nameSelect} <DownOutlined />
        </TitleLinkStyled>
      </Dropdown>
    </>
  );
};

FilterRoadmap.propTypes = {
  getFilter: PropTypes.any,
};
export default FilterRoadmap;

const TitleLinkStyled = styled.a`
  :hover {
    color: var(--blue-dark);
  }
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: normal;
  font-size: var(--body);
  color: var(--blue-dark);
`;
