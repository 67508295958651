import React from "react";
import styled from "styled-components";
import { Menu, Dropdown } from "antd";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  getAllObservations,
  filterTypeByMilestone,
  filterTypeByRisks,
  filterTypeByProblem,
  filterTypeByCommentary,
} from "../../redux/observation/observationDucks";

import config from "../../config";

const svg = (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.41 0L6 4.58L10.59 0L12 1.41L6 7.41L0 1.41L1.41 0Z"
      fill="#707070"
    />
  </svg>
);

const Options = [
  { title: "Comentarios", action: 1 },
  { title: "Problemas", action: 2 },
  { title: "Riesgos", action: 3 },
  { title: "Hitos de caso de negocio", action: 4 },
  { title: "Todos", action: 5 },
];
function FilterComponent() {
  const [selectName, setSelectName] = React.useState("Filtros");
  const dispatch = useDispatch();

  const handleFilter = async (el) => {
    const resp = await axios.get(`${config.api_sheet}/observaciones`);

    if (el.action === 1) {
      dispatch(filterTypeByCommentary(resp.data));
      setSelectName(el.title);
    } else if (el.action === 2) {
      dispatch(filterTypeByProblem(resp.data));
      setSelectName(el.title);
    } else if (el.action === 3) {
      dispatch(filterTypeByRisks(resp.data));
      setSelectName(el.title);
    } else if (el.action === 4) {
      dispatch(filterTypeByMilestone(resp.data));
      setSelectName(el.title);
    } else if (el.action === 5) {
      dispatch(getAllObservations(resp.data));
      setSelectName(el.title);
    }
  };

  const menu = (
    <Menu>
      {Options.map((el, i) => {
        return (
          <Menu.Item key={i}>
            <ItemFilter onClick={() => handleFilter(el)}>{el.title}</ItemFilter>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <>
      <Filter>
        <Dropdown overlay={menu} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <span>
              {selectName} {svg}
            </span>
          </a>
        </Dropdown>
      </Filter>
    </>
  );
}

export default FilterComponent;
const Filter = styled.div`
  display: flex;
  align-items: center;
  span {
    font-family: var(--font-opensans);
    font-style: normal;
    font-weight: normal;
    font-size: var(--body);
    color: var(--blue-dark);
    left: -1em;
    position: relative;
  }
`;
const ItemFilter = styled.div`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: normal;
  font-size: var(--body);
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  // text-decoration-line: underline;
  color: var(--blue-dark);
  margin: 0 0.5em;
  cursor: pointer;
`;
