import React from "react";
import { TinyLine } from "@ant-design/charts";
import PropTypes from "prop-types";

const DemoTinyLine = ({ data }) => {
  var config = {
    height: 120,
    width: 300,
    autoFit: false,
    data: data,
    smooth: true,
    lineStyle: {
      stroke: "#000",
      lineWidth: 3,
      cursor: "pointer",
    },
  };
  return <TinyLine {...config} />;
};

DemoTinyLine.propTypes = {
  data: PropTypes.array,
};
export default DemoTinyLine;
