import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import FilterRoadmap from "../components/Filter/FilterRoadmap";
import Filter from "../components/Filter/Filter";

import Header from "../components/Default/Header";
import ContainerCounter from "../components/ContainerCounter/ContainerCounter";
import svgRoadmap from "../assets/images/roadmap.svg";

import { getAllData } from "../redux/initiative/initiativeDucks";
import config from "../config";
import Gantt from "../components/Chart/Gantt";
import Card from "../components/Default/Card";
import DetailByInitiative from "../components/Roadmap/DetailByInitiative";
import DetailByProgram from "../components/Roadmap/DetailByProgram";

function RoadMap() {
  const allDataInitiatives = useSelector((store) => store.initiative.data);
  // const activeFilter = useSelector((store) => store.initiative.activeFilter);
  const selectIdInitiative = useSelector((store) => store.roadmap.initativeId);
  const selectIdItemMenu = useSelector(
    (store) => store.roadmap.selectIdItemMenu
  );
  const [visibleFilter, setVisibleFilter] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const resp = await axios.get(`${config.api_sheet}/base_2021`);
    dispatch(getAllData(resp.data));
  };

  //Total de counter
  const totalBenefCapt = () => {
    let acum = allDataInitiatives.reduce(
      (accu, currentValue) => accu - -currentValue.benef_captura,
      0
    );
    return acum;
  };
  const totalBenefCompr = () => {
    let acum = allDataInitiatives.reduce(
      (accu, currentValue) => accu - -currentValue.benef_compr,
      0
    );
    return parseInt(acum);
  };

  const totalMetaAnio = () => {
    let number =
      allDataInitiatives.length > 0
        ? Math.max(...allDataInitiatives.map((el) => el.meta_benef_año))
        : 0;
    return number;
  };

  // const totalMetaByCountry = () => {
  //   let number =
  //     allDataInitiatives.length > 0
  //       ? Math.max(...allDataInitiatives.map((el) => el.meta_pais))
  //       : 0;
  //   return number;
  // };

  //Total promedio real / plan
  const totalAvancePercent = () => {
    let prome = totalBenefCapt()
      ? (totalBenefCapt() / totalMetaAnio()) * 100
      : 0;
    return prome;
  };

  //Total promedio proyectado / plan
  const totalProyecPercent = () => {
    let prome = totalBenefCompr()
      ? (totalBenefCompr() / totalMetaAnio()) * 100
      : 0;
    return prome;
  };

  // const validateByFilterCountry = activeFilter
  //   ? totalMetaByCountry()
  //   : totalMetaAnio();

  const data = [
    {
      title: "Total iniciativas",
      counter: allDataInitiatives.length,
    },
    {
      title: "Total capex",
      counter: totalBenefCapt() > 0 ? totalBenefCapt().toLocaleString() : 0,
    },
    {
      title: "Benefecio esperado",
      counter: `${totalAvancePercent().toFixed(0)} %`,
    },
    {
      title: "Beneficio capturado",
      counter: totalBenefCompr() > 0 ? totalBenefCompr().toLocaleString() : 0,
    },
    {
      title: "% Beneficio capturado",
      counter: `${
        totalProyecPercent() > 0 ? totalProyecPercent().toFixed(0) : 0
      } %`,
    },
  ];

  const showDrawer = () => {
    setVisibleFilter(true);
  };
  const onClose = () => {
    setVisibleFilter(false);
  };
  //Data all initiatives
  const dataMap = allDataInitiatives.map((el) => {
    return [
      el.id,
      el.titulo_proy,
      moment(el.aux_fecha_creacion)._d,
      moment(el.aux_fecha_fin_compr)._d,
      null,
      null,
      null,
    ];
  });

  //remove duplicate Programs
  let arrayProgramsClearDuplicate = allDataInitiatives.filter(
    (v, i, a) => a.findIndex((t) => t.programa === v.programa) === i
  );
  //Data program by initiatives
  const dataMapByPrograms = arrayProgramsClearDuplicate.map((el) => {
    return [
      el.id,
      el.programa,
      moment(el.fecha_creacion)._d,
      moment(el.fecha_fin_compr)._d,
      null,
      null,
      null,
    ];
  });
  const drawerFilter = <Filter onClose={onClose} visible={visibleFilter} />;
  return (
    <>
      {drawerFilter}
      <Header
        title="RoadMap"
        icon={svgRoadmap}
        btnLogout
        btnFilter
        drawerShowFilter={showDrawer}
      />
      <ContainerCounter data={data} />
      <Row>
        <Col className="col-one">
          <Div>
            {selectIdItemMenu === 1 ? (
              <TitleStyled>
                Listado de iniciativas ({allDataInitiatives.length})
              </TitleStyled>
            ) : (
              <TitleStyled>
                Listado de programas ({arrayProgramsClearDuplicate.length})
              </TitleStyled>
            )}

            <FilterRoadmap />
          </Div>
          <ContainerGantt>
            {selectIdItemMenu === 1 ? (
              <Gantt data={dataMap} />
            ) : (
              <Gantt data={dataMapByPrograms} />
            )}
          </ContainerGantt>
        </Col>
        <Col className="col-two">
          <div style={{ marginTop: "2.5em" }} />
          <Card>
            {selectIdItemMenu === 1 ? (
              <DetailByInitiative idInitiative={selectIdInitiative} />
            ) : (
              <DetailByProgram idInitiative={selectIdInitiative} />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RoadMap;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px;
  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 992px) {
    .col-one {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    .col-two {
      grid-column-start: 3;
      grid-column-end: 5;
    }
  }
`;
const Col = styled.div``;

const TitleStyled = styled.h2`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: var(--subtitle);
  line-height: 27px;
  color: var(--blue-dark);
  margin-left: 0.2em;
`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContainerGantt = styled.div`
  height: 575px;
  overflow: auto;
  background: #f3f3f3;
`;
