import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Item from "../Default/Item";

function DetailByProgram(props) {
  const { idInitiative } = props;
  const allDataInitiatives = useSelector((store) => store.initiative.data);

  const compareInitiativeWithId = allDataInitiatives.filter(
    (el) => el.id === idInitiative
  );

  const programByIniatitive = allDataInitiatives.filter(
    (el) => el.programa_id === compareInitiativeWithId[0]?.programa_id
  );

  return (
    <>
      {idInitiative && (
        <TitleStyled>
          Programa {compareInitiativeWithId[0]?.programa}
        </TitleStyled>
      )}
      <Container>
        {programByIniatitive.map((el, i) => {
          return (
            <div key={i}>
              <Item
                backgroundColor={"var(--gray-medium)"}
                borderRadius={5}
                height={"auto"}
              >
                <BodyStyled>
                  <TitleBodyStyled>{el.titulo_proy}</TitleBodyStyled>
                  <DescriptionStyled>{el.descr}</DescriptionStyled>
                  <ContentStyled>
                    Ingeniero a cargo: {el.ing_exop}
                  </ContentStyled>
                </BodyStyled>
              </Item>
            </div>
          );
        })}
      </Container>
    </>
  );
}
DetailByProgram.propTypes = {
  idInitiative: PropTypes.string,
};
export default DetailByProgram;

const TitleStyled = styled.h1`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: bold;
  font-size: var(--subtitle);
  color: var(--blue-dark);
  padding: 15px 15px 0px;
`;

const Container = styled.div`
  padding: 0em 1em;
  margin-bottom: 1em;
  height: 380px;
  overflow: auto;
`;
const BodyStyled = styled.div`
  margin: 1em 0;
  padding: 5px 15px 40px;
`;

const TitleBodyStyled = styled.p`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: 600;
  font-size: var(--body);
  color: var(--blue-dark);
`;

const DescriptionStyled = styled.p`
  font-weight: 400;
  font-size: var(--body);
  color: var(--blue-dark);
  height: 100px;
  overflow: auto;
`;

const ContentStyled = styled.p`
  font-family: var(--font-opensans);
  font-style: normal;
  font-weight: normal;
  font-size: var(--body);
  color: var(--gray-dark);
  float: right;
`;
