const dataInitial = {
  initativeId: "",
  selectIdItemMenu: 1,
};

const SELECT_ID_INITIATIVE = "SELECT_ID_INITIATIVE";
const SELECT_ID_ITEM_MENU = "SELECT_ID_ITEM_MENU";

export default function roadmapReducer(state = dataInitial, action) {
  const { type, payload } = action;

  switch (type) {
    case SELECT_ID_INITIATIVE:
      return { ...state, initativeId: payload };
    case SELECT_ID_ITEM_MENU:
      return { ...state, selectIdItemMenu: payload };

    default:
      return { ...state };
  }
}

export const selectId = (data) => (dispatch) => {
  dispatch({ type: SELECT_ID_INITIATIVE, payload: data });
};

export const selectIdItemMenu = (data) => (dispatch) => {
  dispatch({ type: SELECT_ID_ITEM_MENU, payload: data });
};
